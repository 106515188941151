<template>
  <div class="container">
    <!-- <div class="block-module">
      <div class="tab-header">
        <tabs-tmpe />
      </div>
    </div> -->
    <div class="block-module not-border-bottom">
      <div class="insurance-main">
        <ul class="service-list">
          <li class="sl-item" v-for="(item,index) in service_list" :key="index">
            <div class="sl-icon">
              <el-image :src="item.icon" fit="scale-down"></el-image>
            </div>
            <p class="sl-text">{{item.text}}</p>
          </li>
        </ul>
        <ul class="insurance-list">
          <li class="il-item" v-for="(item,index) in insuranceData.list" :key="index">
            <div class="il-link">
              <div class="il-cover">
                <el-image :src="item.images[0]" fit="scale-down"></el-image>
                <!-- <div class="cover-tag">
                  <el-image
                    :src="ossUrl + '/images/insurance-img/insurance-cover-tag (1).png'"
                    fit="scale-down"
                  ></el-image>
                </div> -->
              </div>
              <div class="il-info">
                <h3 class="il-title">{{item.name}}</h3>
                <p class="il-descriptions">{{item.subhead}}</p>

                <ul class="il-clause" v-if="item.plan[0]">
                  <li class="clause-item" v-for="(citem,cindex) in item.plan[0].safeguard" :key="cindex"
                    v-show="cindex < 6">{{citem.text}}：{{citem.value}}</li>
                </ul>
                <p class="il-omit">........</p>
              </div>
              <div class="il-details">
                <div class="il-price">
                  <span class="price-text">￥{{item.plan[0].time_limit[0].money}}</span>
                  <span class="price-unit">/人起</span>
                </div>
                <div class="il-submit">
                  <router-link :to="'/insurance/index?id=' + item.id">
                    <el-button class="fake-btn" round>立即投保</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import tabsTmpe from '@/views/insurance/components/tabs-temp/tabs-temp.vue';
  export default {
    // 允许组件模板递归地调用自身
    name: 'InsuranceList',
    // 声明一组可用于组件实例中的组件
    components: {
      tabsTmpe,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        ossUrl: this.GLOBAL.ossUrl,
        service_list: [{
          icon: this.GLOBAL.ossUrl + '/images/insurance-img/service-icon (1).png',
          text: '保监会权威认证',
        }, {
          icon: this.GLOBAL.ossUrl + '/images/insurance-img/service-icon (2).png',
          text: '价格透明购保无忧',
        }, {
          icon: this.GLOBAL.ossUrl + '/images/insurance-img/service-icon (3).png',
          text: '定制产品性价比高',
        }, {
          icon: this.GLOBAL.ossUrl + '/images/insurance-img/service-icon (4).png',
          text: '投保便捷理赔高效',
        }, {
          icon: this.GLOBAL.ossUrl + '/images/insurance-img/service-icon (5).png',
          text: '专属客服7*24小时协助',
        }],
        insuranceData: { // 保险数据
          list: [], // 列表
        }
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取保险数据列表
        this.getInsuranceDataFun();
      },
      /**
       * 获取保险数据列表
       */
      getInsuranceDataFun() {
        this.myRequest({
            url: '/api/insurance/list'
          })
          .then((res) => {
            console.log('获取保险数据列表 res == ', res)
            let resData = res.data.data;
            this.insuranceData = Object.assign(this.insuranceData, resData);
            console.log('保险新数据 this.insuranceData == ', this.insuranceData)
          }).catch((err) => {
            console.log('获取保险数据列表 err == ', err)
          })
      }
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .container {
    box-sizing: border-box;
    height: 100%;
  }

  .tab-header {
    box-sizing: border-box;
    padding: 10px 0;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }

  .insurance-main {
    box-sizing: border-box;
    padding: 0 $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;

    .service-list {
      display: flex;
      box-sizing: border-box;
      padding: 0 40px;
      margin: 60px 0;

      .sl-item {
        flex: 1;
        min-width: 0;
        text-align: center;

        .sl-icon {
          margin: 0 auto;
          width: 80px;
          height: 80px;
          line-height: 80px;

          .iconfont {
            font-size: 50px;
            color: #787878;

            &.icon-dingzhi {
              font-size: 58px;
            }

            &.icon-kuaisugaoxiao {
              font-size: 44px;
            }

            &.icon-kefu {
              font-size: 52px;
            }
          }
        }

        .sl-text {
          margin-top: 15px;
          font-size: 18px;
          color: #444343;
        }
      }
    }

    .insurance-list {
      box-sizing: border-box;
      padding-bottom: 60px;

      .il-item {
        margin-top: 35px;

        .il-link {
          box-sizing: border-box;
          padding: 50px 20px;
          display: flex;
          align-items: center;
          border-radius: 10px;
          overflow: hidden;
          background: #fff;
          box-shadow: 0 0 14px -7px rgba(0, 0, 0, .6);
        }

        .il-cover {
          position: relative;
          width: 356px;
          height: 257px;
          border-radius: 10px;
          overflow: hidden;
          font-size: 0;

          .fake-img {
            width: 100%;
            height: 100%;
          }

          .cover-tag {
            box-sizing: border-box;
            padding: 4px 8px;
            position: absolute;
            top: 0;
            left: 22px;
            width: 80px;
            height: 34px;
            border-radius: 0 0 10px 10px;
            overflow: hidden;
            background: #fff;
          }
        }

        .il-info {
          $padding-left: 20px;
          box-sizing: border-box;
          padding: 0 70px;
          flex: 1;
          min-width: 0;
          min-height: 300px;

          .il-title {
            font-weight: 400;
            font-size: 28px;
            color: #444343;
          }

          .il-descriptions {
            box-sizing: border-box;
            padding-left: calc(#{$padding-left} / 2);
            margin: 20px 0 28px;
            font-size: 16px;
            color: #AAAAAA;
          }

          .il-clause {
            .clause-item {
              box-sizing: border-box;
              padding-left: $padding-left;
              margin-top: 18px;
              position: relative;
              font-size: 18px;
              color: #444343;

              &:first-of-type {
                margin: 0;
              }

              &::before {
                content: '';
                position: absolute;
                top: 7px;
                left: 0;
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                overflow: hidden;
                background: #AAAAAA;
              }
            }
          }

          .il-omit {
            box-sizing: border-box;
            padding-left: $padding-left;
            font-size: 16px;
            color: #444343;
          }
        }

        .il-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // width: 356px;
          width: 288px;
          height: 260px;
          border-left: 1px solid #999999;

          .il-price {
            display: flex;
            align-items: flex-end;
            color: #E62129;

            .price-text {
              font-size: 42px;
            }

            .price-unit {
              box-sizing: border-box;
              padding-left: 10px;
              position: relative;
              top: -6px;
              font-size: 18px;
            }
          }

          .il-submit {
            margin-top: 60px;

            .fake-btn {
              width: 180px;
              height: 48px;
              padding: 0;
              font-size: 18px;
              color: #E62129;
              border-radius: 48px;
              border: 1px solid #E62129;
              outline: none;

              .iconfont {
                margin-right: 6px;
              }
            }

            &::v-deep {
              .el-button {
                background-color: none;

                &:hover,
                &:focus,
                &:active {
                  outline: none;
                  color: none;
                  border-color: none;
                  background: none;
                }
              }
            }
          }
        }
      }
    }
  }

</style>
