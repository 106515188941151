<template>
  <div class="sub-account-container">
    <div class="add-module">
      <el-button class="fake-btn" @click="openAddAccountDialogFun">
        <span>新增子账号</span>
      </el-button>
    </div>
    <div class="serach-module">
      <div class="sm-head">
        <div class="form-module">
          <div class="fm-tr">
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">手机号：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input maxlength="11" v-model="formData.keyword" placeholder="请输入联系电话"
                      @input="inputChangeFun($event,'keyword')"></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul class="sm-control">
          <li class="control-item serach-btn">
            <el-button class="fake-btn" @click="clickSerachAccountFun">
              <span>查询</span>
            </el-button>
          </li>
          <li class="control-item reset-btn" @click="clickResetAccountFun">
            <el-button class="fake-btn">
              <span>重置</span>
            </el-button>
          </li>
        </ul>
      </div>
      <div class="sm-body">
        <div class="sm-table">
          <el-table border row-class-name="fake-table-tr" :data="accountData.tableData.list" style="width: 100%">
            <el-table-column label-class-name="fake-table-th" width="100px" align="center" prop="name" label="序号">
              <template slot-scope="scope">
                <span v-if="(scope.$index + 1) < 10">00{{scope.$index + 1}} </span>
                <span v-else-if="(scope.$index + 1) >= 10 || (scope.$index + 1) < 100">0{{scope.$index +1}} </span>
                <span v-else>{{scope.$index + 1 }} </span>
              </template>
            </el-table-column>
            <el-table-column label-class-name="fake-table-th" align="center" prop="mobile" label="手机号">
            </el-table-column>
            <el-table-column label-class-name="fake-table-th" align="center" prop="notes" label="备注">
            </el-table-column>
            <el-table-column label-class-name="fake-table-th" align="center" prop="share_wallet" label="是否享用主帐号钱包余额">
              <template slot-scope="scope">
                <span v-if="scope.row.share_wallet == 1">是</span>
                <span v-else-if="scope.row.share_wallet == 0">否</span>
              </template>
            </el-table-column>
            <el-table-column label-class-name="fake-table-th" align="center" label="操作">
              <template slot-scope="scope">
                <div class="control-list">
                  <span class="control-item" :data-data="scope.$index" @click="reviseAccountFun(scope.row)">修改</span>
                  <!-- <span class="control-item" :data-data="scope.$index"
                    @click="delAccountFun(scope.row,scope.$index)">删除</span> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-paging">
          <div class="center-place">
            <span class="sum-number">共{{accountData.resData.total}}条记录</span>
            <el-pagination background layout="prev, pager, next" :current-page="formData.page"
              :page-size="formData.page_size" :page-count="accountData.resData.page_total"
              :total="accountData.resData.total" @current-change="pageChageFun">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗-添加子账号 -->
    <add-account-dialog :ref="accountData.addParam.ref" :visible="accountData.addParam.visible"
      :options="accountData.addParam.options" @on-change="addAccountDialogChangeFun" />

    <!-- 对话框 -->
    <my-dialog :visible="myDialog.visible" :options="myDialog.options" @on-change="myDialogChangeFun" />

  </div>
</template>

<script>
  import {
    deepClone, // 深拷贝
  } from '@/utils/utils_fun.js';
  import addAccountDialog from '@/views/insurance/child-pages/sub-account/template/add-account-dialog.vue'
  export default {
    // 允许组件模板递归地调用自身
    name: 'SubAccount.vue',
    // 声明一组可用于组件实例中的组件
    components: {
      addAccountDialog,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        userInfo: {}, // 用户信息
        page_total: 1, // null, // 数据总页数
        data_total: 1, // null, // 数据总条数
        myDialog: {
          data: {},
          visible: false,
          options: {},
        },
        formData: {
          page: 1,
          page_size: 20,
          keyword: '',
        },
        accountData: { // 子账号数据
          resData: {}, // 响应数据
          addParam: { // 添加子账号参数
            ref: 'add-account-dialog',
            visible: false,
            options: {},
          },
          tableData: {
            radio: '',
            checked: 0,
            checkList: [],
            list: []
          }
        },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 输入框监听变化
       */
      inputChangeFun(event, name) {
        console.log(event)
        console.log(name)
        console.log(this.formData)
      },
      /**
       * 初始化对话框
       */
      initMyDialogFun() {
        this.myDialog = {
          data: {},
          visible: false,
          options: {},
        }
      },
      /**
       * 对话框回调
       */
      myDialogChangeFun(e) {
        console.log('对话框回调 e == ', e);
        let {
          visible,
          status,
          message,
          data,
          name,
          sign
        } = e;
        if (status == 1) { // 确认
          // 删除-子账号-请求
          this.delAccountRequest();
        } else { // 取消
          // 初始化对话框
          this.initMyDialogFun();
        }
      },
      /**
       * 删除-子账号
       */
      delAccountFun(item, index) {
        console.log('删除-子账号 item == ', item)
        this.myDialog = {
          data: item,
          visible: true,
          options: {
            title: '删除',
            message: `确认要删除名为[${item.name}]的数据？`,
            is_tips: false,
            sign: 'applicant',
          },
        }
      },
      /**
       * 删除-子账号-请求
       */
      delAccountRequest() {
        let {
          data
        } = this.myDialog;
        this.myRequest({
          url: '/api/sonaccount/list',
          data: {
            id: data.id,
          }
        }).then((res) => {
          // 初始化-子账号数据
          this.initAccountDataFun();

          // 获取保险数据列表
          this.getAccountDataFun();
          // 初始化对话框
          this.initMyDialogFun();
          console.log('删除-子账号 res == ', res);
        })
      },
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取保险数据列表
        this.getAccountDataFun();
        // 获取账号信息
        this.getUserInfoFun().then(res => {});
      },
      /**
       * 获取账号信息
       */
      getUserInfoFun() {
        return new Promise((resolve) => {
          this.myRequest({
            url: '/api/user/userinfostatus',
          }).then((res) => {
            console.log('获取账号信息 res == ', res)
            let resData = res.data.data;
            this.userInfo = resData;
            if (typeof this.userInfo == 'object') {
              this.userInfo.loading = true;
            }
            resolve(resData);
          })
        })
      },
      /**
       * 初始化-子账号数据
       */
      initAccountDataFun() {
        this.accountData.tableData.list = [];
        // this.accountData = { // 子账号数据
        //   resData: {}, // 响应数据
        //   addParam: { // 添加子账号参数
        //     ref: 'add-account-dialog',
        //     visible: false,
        //     options: {},
        //   },
        //   tableData: {
        //     radio: '',
        //     checked: 0,
        //     checkList: [],
        //     list: []
        //   }
        // };
      },
      /**
       * 获取子账号数据
       */
      getAccountDataFun() {
        this.myRequest({
            url: '/api/sonaccount/list',
            data: this.formData,
          })
          .then((res) => {
            console.log('获取子账号数据 res == ', res)
            let resData = res.data.data;
            this.accountData.resData = Object.assign(this.accountData.resData, resData);
            this.accountData.tableData.list = this.accountData.tableData.list.concat(resData.list);
            console.log('获取子账号数据 this.accountData == ', this.accountData)
          }).catch((err) => {
            console.log('获取子账号数据 err == ', err)
          })
      },
      /**
       * 分页切换
       */
      pageChageFun(e) {
        console.log('分页切换 e == ', e)
        this.formData.page = e;
        // 初始化-子账号数据
        this.initAccountDataFun();
        // 获取保险数据列表
        this.getAccountDataFun();
      },
      /**
       * 打开-新增子账号
       */
      openAddAccountDialogFun() {

        if (this.userInfo.is_son_account) {
          this.myMessage({
            message: '您当前已是子账号，无权限添加，如有疑问请联系客服！',
          });
          return;
        }

        this.accountData.addParam.visible = true; // 子账号数据
      },
      /**
       * 编辑-子账号
       */
      reviseAccountFun(item) {
        console.log('编辑-子账号 item == ', item)
        let copy_data = deepClone(item); // 深拷贝
        this.accountData.addParam.options = copy_data; // 编辑数据
        // 打开-添加子账号-弹窗
        this.openAddAccountDialogFun();
      },
      /**
       * 新增子账号-回调
       */
      addAccountDialogChangeFun(e) {
        console.log('回调 e == ', e)
        this.accountData.addParam.visible = false; // 关闭弹窗
        let {
          status,
          data = []
        } = e;
        if (status == 1) {
          // this.accountData.tableData.list = this.accountData.tableData.list.concat(data);
          // 初始化-子账号数据
          this.initAccountDataFun();
          // 获取保险数据列表
          this.getAccountDataFun();
        }
        console.log('this.accountData == ', this.accountData)
      },
      /**
       * 点击查询-子账号
       */
      clickSerachAccountFun() {
        // 初始化-子账号数据
        this.initAccountDataFun();
        // 获取保险数据列表
        this.getAccountDataFun();
      },
      /**
       * 点击重置-子账号请求参数
       */
      clickResetAccountFun() {
        this.formData = {
          page: 1,
          page_size: 10,
          keyword: '',
        };
        // 初始化-子账号数据
        this.initAccountDataFun();

        // 获取保险数据列表
        this.getAccountDataFun();
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .sub-account-container {
    box-sizing: border-box;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;
  }

  .add-module {
    margin: 55px 0;
    width: 110px;

    &::v-deep {
      .fake-btn {
        width: 100%;
        height: 36px;
        padding: 0;
        font-size: 16px;
        color: #fff;
        border: 1px solid #E62129;
        background: #E62129;
        outline: none;

        &.el-button:hover,
        &.el-button:focus,
        &.el-button:active {
          color: none;
          border-color: none;
          background-color: none;
        }

        &.el-button:active {
          color: $active-default-color;
          border-color: $active-default-color;
          background-color: #fff;
        }
      }
    }
  }

  .serach-module {
    .sm-head {
      display: flex;
      align-items: center;

      .sm-control {
        display: flex;
        margin: 0 80px;

        .control-item {
          width: 110px;
          margin-left: 32px;

          &::v-deep {
            .fake-btn {
              width: 100%;
              height: 36px;
              padding: 0;
              font-size: 16px;
              color: #787878;
              border: 1px solid #CCCCCC;
              background: #fff;
              outline: none;

              &.el-button:hover,
              &.el-button:focus,
              &.el-button:active {
                color: none;
                border-color: none;
                background-color: none;
              }

              &.el-button:active {
                color: $active-default-color;
                border-color: $active-default-color;
                background-color: #fff;
              }
            }
          }

          &:first-of-type {
            margin-left: 0;
          }

          &.reset-btn {
            &::v-deep {
              .fake-btn {
                color: #FFFFFF;
                border: 1px solid #AAAAAA;
                background: #AAAAAA;
              }
            }
          }

          &.serach-btn {
            &::v-deep {
              .fake-btn {
                color: #FFFFFF;
                border: 1px solid #E62129;
                background: #E62129;
              }
            }
          }
        }
      }
    }

    .sm-body {
      .sm-table {
        margin: 37px 0 83px;

        &::v-deep {
          .el-checkbox__label {
            display: none;
          }

          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: transparent;
            border-color: #E62129;
          }

          .el-checkbox__input.is-focus .el-checkbox__inner,
          .el-checkbox__inner::after {
            border-color: #E62129;
          }

          .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
            background-color: #E62129;
          }
        }

        &::v-deep {
          .el-table th.el-table__cell {
            background: #F0F0F0;
          }

          .fake-table-th {
            padding: 10px 0;
            background: #F0F0F0;
            font-weight: 400;
            font-size: 16px;
            color: #444343;
          }

          .fake-table-tr {
            font-size: 16px;
            color: #787878;
            background: #fff;
          }

          .el-table--border,
          .el-table--group {
            border: 1px solid #e6e6e6;
          }

          .el-table--border .el-table__cell,
          .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
          .el-table--border th.el-table__cell,
          .el-table__fixed-right-patch {
            border: none;
          }

          .el-table td.el-table__cell,
          .el-table th.el-table__cell.is-leaf {
            border-top: 1px solid #f0f0f0;
          }

          .el-table--border::after,
          .el-table--group::after,
          .el-table::before {
            display: none;
          }

          .el-table,
          .el-table__expanded-cell,
          .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
            background: transparent;
          }

          .el-table .el-table__cell {
            height: 66px;
          }
        }

        .control-list {
          .control-item {
            margin: 0 8px;
            display: inline-block;
            font-size: 16px;
            color: #E62129;
            cursor: pointer;
          }
        }
      }

      .table-paging {
        margin-bottom: 83px;
        display: flex;
        justify-content: center;
        text-align: center;

        .center-place {
          position: relative;

          .sum-number {
            position: absolute;
            top: 50%;
            left: -100%;
            transform: translateY(-50%);
            font-size: 16px;
            color: #787878;
          }
        }
      }
    }

    .form-module {
      .fm-itme {
        margin-top: 0;

        &::v-deep {

          .fm-val .field-item .input-box,
          .fm-val .field-item .input-box .el-input__inner,
          .cascader-box .cascader-box .el-input .el-input__inner .el-input,
          .el-input__inner {
            height: 36px;
          }
        }

        &:first-of-type {
          .fm-key {
            min-width: auto;
          }
        }
      }
    }
  }

</style>
