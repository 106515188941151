<template>
  <div class="done-container">
    <div class="form-container">
      <div class="form-module">
        <div class="fm-tr">
          <div class="fm-itme">
            <div class="fm-key">
              <div class="key-title required-style">出单时间：</div>
            </div>
            <div class="fm-val">
              <div class="picker-date">
                <el-date-picker
                  v-model="start_end_time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  placeholder="请选择您活动的开始时间"
                  value-format="yyyy-MM-dd"
                  @change="function(e) {formData.add_start_time = e[0]; formData.add_start_time = e[1];}"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="fm-itme">
            <div class="fm-key">
              <div class="key-title required-style">投保人姓名：</div>
            </div>
            <div class="fm-val">
              <div class="field-item">
                <div class="input-box">
                  <!-- <el-input v-model="formData.holder_name" placeholder="请输入投保人姓名" @input="inputChangeFun($event,'holder_name')"></el-input> -->
                  <el-autocomplete
                    v-model="formData.holder_name"
                      :class="{'isIphone8Up': facility.ios_grade > 2}"
                    :fetch-suggestions="function(e,cb) { cb(applicantQuerySearch(e,'name'))}"
                    placeholder="请输入投保人姓名"
                    @select="function(item){ formData.holder_name = item['name']}">
                    <template slot-scope="{ item }">
                      <div>{{ item.name }}</div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
            </div>
          </div>
          <div class="fm-itme">
            <div class="fm-key">
              <div class="key-title required-style">投保渠道：</div>
            </div>
            <div class="fm-val">
              <!-- <div class="cascader-box">
                <el-cascader :options="[{value: '1', label: '1', children: [{value:'2',label: '2'}],}]" placeholder="请选择投保渠道" :show-all-levels="false" @change="categoryChangeFun"></el-cascader>
              </div> -->
              <div class="select-box">
                <el-select v-model="formData.client_type" placeholder="请选择投保渠道">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="pc" value="pc"></el-option>
                  <el-option label="小程序" value="weixin"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="fm-itme">
            <div class="fm-key">
              <div class="key-title required-style">生效时间：</div>
            </div>
            <div class="fm-val">
              <div class="picker-date">
                <el-date-picker
                  v-model="order_start_end_time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  placeholder="请选择您活动的开始时间"
                  value-format="yyyy-MM-dd"
                  @change="function(e) {formData.start_time = e[0]; formData.end_time = e[1];}"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="fm-itme">
            <div class="fm-key">
              <div class="key-title required-style">被保人姓名：</div>
            </div>
            <div class="fm-val">
              <div class="field-item">
                <div class="input-box">
                  <!-- <el-input v-model="formData.insured_name" placeholder="请输入被保人姓名" @input="inputChangeFun($event,'insured_name')"></el-input> -->
                  <el-autocomplete
                    v-model="formData.insured_name"
                      :class="{'isIphone8Up': facility.ios_grade > 2}"
                    :fetch-suggestions="function(e,cb) { cb(recognizeeQuerySearch(e,'name'))}"
                    placeholder="请输入被保人姓名"
                    @select="function(item){ formData.insured_name = item['name']}">
                    <template slot-scope="{ item }">
                      <div>{{ item.name }}</div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
            </div>
          </div>
          <div class="fm-itme">
            <div class="fm-key">
              <div class="key-title required-style">被保人证件号：</div>
            </div>
            <div class="fm-val">
              <div class="field-item">
                <div class="input-box">
                  <!-- <el-input maxlength="18" v-model="formData.insured_code" placeholder="请输入被保人证件号" @input="inputChangeFun($event,'insured_code')"></el-input> -->
                  <el-autocomplete
                    v-model="formData.insured_code"
                      :class="{'isIphone8Up': facility.ios_grade > 2}"
                    :fetch-suggestions="function(e,cb) { cb(recognizeeQuerySearch(e,'code'))}"
                    placeholder="请输入被保人证件号"
                    @select="function(item){ formData.insured_code = item['code']}">
                    <template slot-scope="{ item }">
                      <div>{{ item.code }}</div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
            </div>
          </div>
          <div class="fm-itme">
            <div class="fm-key">
              <div class="key-title required-style">订单号：</div>
            </div>
            <div class="fm-val">
              <div class="field-item">
                <div class="input-box">
                  <el-input v-model="formData.order_no" placeholder="请输入订单号" @input="inputChangeFun($event,'order_no')"></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="fm-itme">
            <div class="fm-key">
              <div class="key-title required-style">保单号：</div>
            </div>
            <div class="fm-val">
              <div class="field-item">
                <div class="input-box">
                  <el-input v-model="formData.trade_policy_no" placeholder="请输入保单号" @input="inputChangeFun($event,'trade_policy_no')"></el-input>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="fm-itme">
            <div class="fm-key">
              <div class="key-title required-style">状态：</div>
            </div>
            <div class="fm-val">
              <div class="select-box">
                <el-select v-model="formData.pay_status" placeholder="请选择投保渠道">
                  <el-option label="已完成" value="1"></el-option>
                  <el-option label="已退保" value="2"></el-option>
                </el-select>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="form-module">
        <div class="fm-rest">
          <div class="fm-control">
            <div class="control-item">
              <el-button class="fake-btn" @click="clickSubmitBtnFun">
                <span>立即查询</span>
              </el-button>
            </div>
            <div class="control-item reset-btn">
              <el-button class="fake-btn" @click="clickResetBtnFun">
                <span>重置</span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="simulate-table query-table">
        <div class="st-head">
          <div class="st-tr">
            <div class="st-th">产品</div>
            <div class="st-th">投保人</div>
            <div class="st-th">备注</div>
            <div class="st-th">人数</div>
            <div class="st-th">保费</div>
            <div class="st-th">订单状态</div>
            <div class="st-th">操作</div>
          </div>
        </div>
        <div class="st-body" v-if="tableData.list.length > 0">
          <div class="st-item" v-for="(item,index) in tableData.list" :key="index">
            <div class="tr-before">
              <ul class="details-form">
                <li class="df-item">
                  <span class="df-key">下单时间：</span>
                  <span class="df-value">{{item.add_time_time}}</span>
                </li>
                <li class="df-item">
                  <span class="df-key">保障时间：</span>
                  <span class="df-value">{{item.guarantee_start_time_time}}   至   {{item.guarantee_end_time_time}}</span>
                </li>
                <li class="df-item">
                  <span class="df-key">订单号：</span>
                  <span class="df-value">{{item.order_no}}</span>
                </li>
                <li class="df-item">
                  <span class="df-key">保单号：</span>
                  <span class="df-value">{{item.trade_policy_no}}</span>
                </li>
              </ul>
            </div>
            <div class="st-tr">
              <div class="st-td">{{item.insurance_data.name}}</div>
              <div class="st-td">{{item.person.name}}</div>
              <div class="st-td">{{item.inote ? item.inote : '无'}}</div>
              <div class="st-td">{{item.number}}人</div>
              <div class="st-td">{{item.recognizee_premium}}元</div>
              <div class="st-td">
                <span v-if="item.pay_status == 0">待投保</span>
                <span v-if="item.pay_status == 1">已完成</span>
                <span v-if="item.pay_status == 2">已退保</span>
                <span class="single-failure" v-if="item.pay_status == 3">
                  <span>出单失败</span>
                  <el-tooltip effect="dark" content="" placement="top">
                    <div slot="content">
                      <p>{{item.trade_error_msg}}</p>
                    </div>
                    <em class="icon">
                      <i class="iconfont icon-wenhao-xianxingyuankuang"></i>
                    </em>
                  </el-tooltip>
                </span>
              </div>
              <div class="st-td" v-if="item.pay_status == 0">
                <p class="control-p">
                  <!-- <span class="fake-a" @click="toHaveThemInsuredFun(item,'amend')">去投保</span> -->
                  <span class="fake-a" @click="skipAnotherListFun(item,1)">去投保</span>
                  <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <span class="fake-a" @click="delOrderFun(item)">删除订单</span>
                </p>
              </div>
              <div class="st-td" v-if="item.pay_status == 1">
                <p class="control-p">
                  <router-link class="fake-a" :to="'/insurance/query/index?id=' + item.id">保单详情</router-link>
                  <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <span class="fake-a" @click="downloadInsuranceFun(item)">下载保单</span>
                </p>
                <p class="control-p">
                  <span class="fake-a" @click="applyInvoiceFun(item)" v-if="(item.insurance_data.invoice_link)">下载发票</span>
                  <template v-else>
                    <span class="fake-a">
                      <span v-if="item.invoice_status == 0" @click="applyInvoiceTwoFun(item)">申请发票</span>
                      <span v-if="item.invoice_status == 1" @click="downloadInvoiceTwoFun(item)">下载发票</span>
                      <span v-if="item.invoice_status == 2">发票申请中</span>
                      <span v-if="item.invoice_status == 3">
                        <el-tooltip effect="dark" content="" placement="top">
                          <div slot="content">
                            <p>{{item.invoice_explain}}</p>
                          </div>
                          <span>发票申请失败</span>
                        </el-tooltip>
                      </span>
                    </span>
                  </template>
                  <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <span class="fake-a" @click="revocationInsuranceFun(item)">申请退保</span>
                  <!-- <span class="fake-a" @click="delOrderFun(item)">删除保单</span> -->
                </p>
                <!-- <p class="control-p">
                  <span class="fake-a" @click="revocationInsuranceFun(item)">退保</span>
                </p> -->
                <!-- <p class="control-p" >
                  <span class="fake-a" @click="skipAnotherListFun(item,2)">再来一单</span>
                </p> -->
              </div>
              <div class="st-td" v-if="item.pay_status == 2">
                <p class="control-p">
                  <span class="fake-a" @click="skipAnotherListFun(item,2)">再来一单</span>
                  <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <span class="fake-a" @click="delOrderFun(item)">删除订单</span>
                </p>
                <!-- <p class="control-p">
                  <router-link class="fake-a" :to="'/insurance/query/index?id=' + item.id">保单详情</router-link>
                  <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <span class="fake-a" @click="downloadInsuranceFun(item)">下载保单</span>
                </p> -->
              </div>
              <div class="st-td" v-if="item.pay_status == 3">
                <p class="control-p">
                  <span class="fake-a" @click="skipAnotherListFun(item,2)">再来一单</span>
                  <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <span class="fake-a" @click="delOrderFun(item)">删除订单</span>
                </p>
                <!-- <p class="control-p">
                  <router-link class="fake-a" :to="'/insurance/query/index?id=' + item.id">保单详情</router-link>
                  <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <span class="fake-a" @click="downloadInsuranceFun(item)">下载保单</span>
                </p> -->
              </div>
              <!-- <div class="st-td" v-else>
              </div> -->
            </div>
          </div>
        </div>
        <div class="st-empty" v-else>
          <i class="iconfont icon-empty"></i>
        </div>
      </div>
      <div class="table-overview">
        <div class="table-statistics">
          <ul class="details-form">
            <li class="df-item">
              <span class="df-value">共{{policyData.total}}条记录</span>
            </li>
            <li class="df-item">
              <span class="df-key">保费总计：</span>
              <span class="df-value">￥{{policyData.recognizee_premium_total}}</span>
            </li>
          </ul>
        </div>
        <div class="table-paging">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="formData.page"
            :page-count="policyData.page_total"
            :total="policyData.total"
            @current-change="pageChageFun"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 对话框 -->
    <my-dialog :visible="myDialog.visible" :options="myDialog.options" @on-change="myDialogChangeFun" />

    <!-- 支付弹窗 -->
    <my-pay-dialog :visible="pay_config.visible" :price="pay_config.price" :qr-code="pay_config.qr_code" @on-change="payResultChangeFun" />

    <!-- 外部链接抽屉 -->
    <external-link-drawer :visible="externalLink.visible" :options="externalLink.options" @on-change="function(e) { externalLink.visible = false; externalLink.options = {};}" />

  </div>
</template>

<script>
import ajax from '@/utils/my_ajax.js';
import simblateTable from '@/views/insurance/components/simblate-table/SimulateTable.vue';
import myPayDialog from '@/components/my-pay-dialog/MyPayDialog.vue';
import externalLinkDrawer from '@/components/external-link-drawer/external-link-drawer.vue';
export default {
  // 允许组件模板递归地调用自身
  name: 'Done',
  // 声明一组可用于组件实例中的组件
  components: {
    simblateTable,
    myPayDialog,
    externalLinkDrawer,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    type: {
      type: [String,Number],
      default: 1,
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      facility: {}, // 当前设备
      page_total: 1,// null, // 数据总页数
      data_total: 1,// null, // 数据总条数
      table_list: [], // 表格列表
      myDialog: {
        data: {},
        visible: false,
        options: {},
      },
      externalLink: { // 外部链接
        data: {},
        visible: false,
        options: {},
      },
      start_end_time: '', // 开始结束时间
      order_start_end_time: '', // 出单日期
      policyData: {}, // 保单数据
      formData: {
        page: 1, //	否	string	分页码 默认值：1
        page_size: '10', //	否	string	每页显示条数 默认值：10
        pay_status: this.type, //	否	string	状态【0待投保，1已完成，2已退保】
        client_type: '', //	否	string	渠道【pc，weixin】
        add_start_time: '', //	否	string	出单开始时间（格式：yyyy-mm-dd）
        add_end_time: '', //	是	string	出单结束时间（格式：yyyy-mm-dd）
        guarantee_start_time: '', //	否	string	生效开始时间（格式：yyyy-mm-dd）
        guarantee_end_time: '', //	否	string	生效结束时间（格式：yyyy-mm-dd）
        order_no: '', //	否	string	订单号
        holder_name: '', //	否	string	投保人姓名
        insured_name: '', //	否	string	被保人姓名
        insured_code: '', //	否	string	被保人证件号
        trade_policy_no: '', //	否	string	保单号
      },
      inputHistoryData: { // 历史输入数据
        applicant: [], // 投保人
        recognizee: [], // 被保人
      },
      tableData: {
        list: [],
      },
      pay_config: { // 支付配置
        visible: false,
        pay_timer: null,
        price: '',
        qr_code: '',
      },
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    type: function(e) {
      console.log('子函数--侦听data变 == ',e)
      this.formData.pay_status = e;
      this.tableData.list = [];
      // 获取保单数据
      this.getPolicyDataFun();
    }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 投保人-历史输入-输入建议
     */
    applicantQuerySearch(queryString,key) {
      var restaurants = this.inputHistoryData.applicant;
      var results = queryString ? restaurants.filter(this.createFilter(queryString,key)) : restaurants;
      return results;
    },
    /**
     * 被保人-历史输入-输入建议
     */
    recognizeeQuerySearch(queryString,key) {
      var restaurants = this.inputHistoryData.recognizee;
      var results = queryString ? restaurants.filter(this.createFilter(queryString,key)) : restaurants;
      return results;
    },

    /**
     * 历史输入-输入建议-过滤
     */
    createFilter(queryString,key) {
      return (restaurant) => {
        return (restaurant[key].toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 初始化历史输入
      this.initInputHistory();
      // 获取保单数据
      this.getPolicyDataFun();
      // 获取设备信息
      this.getFacilityInfo();
    },
    /**
     * 获取设备信息
     */
    getFacilityInfo() {
      let facility = this.myGetCurrentFacility();
      console.log('获取设备信息 facility == ',facility)
      this.facility = facility;
    },
    /**
     * 初始化历史输入
     */
    initInputHistory() {
      // 投保人
      let applicant = this.STORAGE.input_history['child_name']['applicant'];
      console.log('获取历史输入 applicant == ',applicant)
      // 获取历史输入
      let applicant_inputHistory = this.myGetStorageInputHistory(applicant);
      if(applicant_inputHistory) {
        let { target = [] } = applicant_inputHistory;
        if(target.length > 0) {
          this.inputHistoryData.applicant = target;
        }
      }

      // 被保人
      let recognizee = this.STORAGE.input_history['child_name']['recognizee'];
      console.log('获取历史输入 recognizee == ',recognizee)
      // 获取历史输入
      let recognizee_inputHistory = this.myGetStorageInputHistory(recognizee);
      if(recognizee_inputHistory) {
        let { target = [] } = recognizee_inputHistory;
        if(target.length > 0) {
          this.inputHistoryData.recognizee = target;
        }
      }
      console.log('获取历史输入 this.inputHistoryData == ',this.inputHistoryData)
    },
    /**
     * 初始化表单数据
     */
    initFormDataFun() {
      this.start_end_time = ''; // 开始结束时间
      this.order_start_end_time = ''; // 出单日期
      this.formData = {
        page: 1, //	否	string	分页码 默认值：1
        page_size: '10', //	否	string	每页显示条数 默认值：10
        pay_status: '1', //	否	string	状态【0待投保，1已完成，2已退保】
        client_type: 'pc', //	否	string	渠道【pc，weixin】
        add_start_time: '', //	否	string	出单开始时间（格式：yyyy-mm-dd）
        add_end_time: '', //	是	string	出单结束时间（格式：yyyy-mm-dd）
        guarantee_start_time: '', //	否	string	生效开始时间（格式：yyyy-mm-dd）
        guarantee_end_time: '', //	否	string	生效结束时间（格式：yyyy-mm-dd）
        order_no: '', //	否	string	订单号
        holder_name: '', //	否	string	投保人姓名
        insured_name: '', //	否	string	被保人姓名
        insured_code: '', //	否	string	被保人证件号
        trade_no: '', //	否	string	保单号
        trade_policy_no: '', //	否	string	保单号
      };
    },
    /**
     * 分页切换
     */
    pageChageFun(e) {
      console.log('分页切换 e == ',e)
      this.formData.page = e;
      this.tableData.list = [];
      // 获取保单数据
      this.getPolicyDataFun();
    },
    /**
     * 类别变换
     */
    categoryChangeFun(e) {
      console.log('类别变换 == ',e)
      console.log('类别变换 this.category_options == ',this.category_options)
    },
    /**
     * 点击重置按钮
     */
    clickResetBtnFun() {
      console.log('重置');
      // 初始化表单数据
      this.initFormDataFun();
      this.tableData.list = [];
      // 获取保单数据
      this.getPolicyDataFun();
    },

    /**
     * 点击提交按钮
     */
    clickSubmitBtnFun() {
      this.tableData.list = [];
      // // 校验数据
      // let flag = this.verifyDataFun();
      // if(!flag) return;

      // 获取保单数据
      this.getPolicyDataFun();
    },
    /**
     * 获取保单数据
     */
    getPolicyDataFun() {
      let formData = this.formData;
      console.log('获取保单数据 this.formData == ',this.formData);
      this.myRequest({
        method: 'post',
        url: '/api/insurance/insuredorderlist',
        data: formData,
      }).then((res) => {
        console.log('获取保单数据 res == ',res);
        let resData = res.data.data;
        this.policyData = Object.assign(this.policyData,resData);
        // this.tableData.list = this.tableData.list.concat(resData.list);
        this.tableData.list = resData.list;
        console.log('获取保单数据 this.tableData == ',this.tableData);
        this.$emit('on-change',resData);
      }).catch((err) => {// 发起录入后
        console.log('录入请求失败 err == ',err)
      })
    },
    /**
     * 校验数据
     */
    verifyDataFun() {
      let form_data = this.formData;
      let images = this.fileList;

      console.log('form_data == ',form_data)
      console.log('images == ',images)

      // 图片
      if(images.length == 0) {
        this.myMessage({
          message: '至少上传一张图片作为封面!'
        });
        return false;
      } else {
        form_data.images = images.map(item => {
          return item.url;
        })
      }

      // 活动类别
      if(!form_data.category_name) {
        this.myMessage({
          message: '请选择活动类别!'
        });
        return false;
      }

      // 活动名称
      if(!form_data.name) {
        this.myMessage({
          message: '请输入活动名称!'
        });
        return false;
      } else if(form_data.name.length > this.name_max_length) {
        this.myMessage({
          message: `活动名称最多填写${this.name_max_length}个字符!`
        });
        return false;
      }

      // 活动区域
      // 如果是选择全国则无须填写城市
      if(form_data.province == '全国' || !form_data.province) {
        form_data.city = '';
        form_data.county = '';
      } else {
        // if(!form_data.province || form_data.province == '空' || form_data.province == '省') {
        //   this.myMessage({
        //     message: '请选择活动区域！'
        //   });
        //   return false;
        // } else
        if(!form_data.city || form_data.city == '空' || form_data.city == '市') {
          this.myMessage({
            message: '请选择完整的活动区域，或者选择默认全国！'
          });
          return false;
        }
      }

      // 活动收益
      if(!form_data.benefit_label) {
        this.myMessage({
          message: '请选择活动收益!'
        });
        return false;
      }

      // 活动天数
      if(!form_data.time_length || !this.REGEXP.is_number.test(form_data.time_length)) {
        this.myMessage({
          message: '请填写活动天数!'
        });
        return false;
      }

      // 活动人数
      if(!form_data.number || !this.REGEXP.is_number.test(form_data.number)) {
        this.myMessage({
          message: '请输入活动人数!'
        });
        return false;
      } else if (parseInt(form_data.number) <= 0) {
        this.myMessage({
          message: '活动人数必须大于0!'
        });
        return false;
      }

      // 活动金额
      if(form_data.price_unit != 1) {
        if(!form_data.price || !this.REGEXP.is_floating.test(form_data.price)) {
          this.myMessage({
            message: '请输入有效的金额，小数点后保留两位数!'
          });
          return false;
        }
      } else {
        form_data.price = 0;
      }

      // 咨询电话
      if(!form_data.tel || !this.REGEXP.is_phone.test(form_data.tel)) {
        this.myMessage({
          message: '请添加有效的咨询电话!'
        });
        return false;
      }


      // 活动介绍
      if(!form_data.introduce) {
        this.myMessage({
          message: '请输入活动介绍!'
        });
        return false;
      }


      return true;
    },
    /**
     * 跳转 修改/再来一单
     * @param {Object} item
     * @param {String} type // 1/amend/修改 2/again/再来一单
     */
    skipAnotherListFun(item,type) {
      let { id, insurance_id } = item;
      this.$router.push({
        path:'/insurance/index',
        query:{
          id: insurance_id,
          order_id: id,
          type: type,
        }
      })
    },
    /**
     * 保单下载
     */
    downloadInsuranceFun(item){
      let { order_no,trade_no } = item;
      var self = this;
      if(!trade_no){
        self.myMessage({
          message: '保单不存在'
        });
        return;
      }
      ajax({
        url : this.GLOBAL.insurance.requestConfig.standbyUrl+"/downorderploxyfile/"+trade_no,
        type : "POST",   // type ---> 请求方式
        async : true,   // async----> 同步：false，异步：true
        data : {        //传入信息
          "loginname": this.GLOBAL.insurance.accountConfig.account,
          "loginpwd": this.GLOBAL.insurance.accountConfig.passwrod,
          "filetype": 0,
        },
        success : function(data){   //返回接受信息
          if(data.errorMsg){
            self.myMessage({
              message: data.errorMsg
            });
          }else{
            var binaryData = [];
            binaryData.push(data);
            //获取blob链接
            var pdfUrl = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
            // window.open(pdfUrl,'_blank');
            // 下载并保存文件
            self.myDownloadFile(pdfUrl);
            // self.thirdDownloadFile(pdfUrl);
          }
        },
        error : function(data){
          self.myMessage({
            message: '网络错误!'
          });
        }
      })
    },
    /**
     * 去投保（支付）
     */
    toHaveThemInsuredFun(item) {
      const _this = this;
      let { id,recognizee_premium } = item;
      // 保单支付
      this.myInsurancePayRequest(id).then(res => {
        console.log('保单支付 res == ',res)
        let resData = res.data.data;

        // 循环请求
        this.myLoopRequest({id: resData.order_id,type: 'insurance_order'},function(res) {
          // console.log('myLoopRequest == ',res)
          let { timer,data } = res;

          // 获取定时器
          if(!_this.pay_config.pay_timer) {
            _this.pay_config.pay_timer = timer;
            // 打开支付弹窗
            _this.openPayWindowFun({price: recognizee_premium, qr_code: resData.pay});
          }
          // console.log('_this.pay_config.pay_timer == ',_this.pay_config.pay_timer)

          // console.log('data.pay_status == ',data.pay_status)
          // 是否已支付
          if(data.pay_status == 1) {
            console.log('是否已支付 == ',data.pay_status)
            // clearInterval(_this.pay_config.pay_timer);
            // 支付结果回调
            _this.payResultChangeFun({
              visible: false,
              status: 1, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
              message: '支付成功',
              data: '',
            });
          } else if( status == 3 ) { // 超时
            // 支付结果回调
            _this.payResultChangeFun(res);
          }
        })
      });
    },
    /**
     * 删除订单
     */
    delOrderFun(item) {
      console.log('删除订单 item == ',item)
      let { pay_status } = item;
      let message = pay_status == 0 ? '订单' : '保单';
      this.myDialog = {
        data: item,
        visible: true,
        options: {
          title: '删除确认',
          message: `是否删除该${message}？`,
          is_tips: false,
          // tips: '退保成功后您购买的保险金额将会在7个工作日之内原路退回您的账户',
          cancel_text: '取消',
          confirm_text: '删除',
          sign: 'delete',
        },
      }
    },
    /**
     * 删除订单-请求
     */
    delOrderRequest() {
      let {data} = this.myDialog;
      return this.myRequest({
        url: '/api/insurance/Insuredorderdelete',
        data: {
          id: data.id,
        }
      })
      // .then((res) => {
      //   console.log('撤销/退保-保险 res == ', res);

      // })
    },
    /**
     * 撤销/退保-保险
     */
    revocationInsuranceFun(item) {
      console.log('撤销/退保-保险 item == ',item)
      console.log('撤销/退保-保险 item.guarantee_effectively == ',item.guarantee_effectively)
      let { guarantee_effectively } = item;
      if(guarantee_effectively == 1) {
        this.myMessage({
          offset: 150,
          message: '您购买的保单已生效，无法退保，如有疑问请联系客服！'
        })
        return;
      }
      this.myDialog = {
        data: item,
        visible: true,
        options: {
          title: '退保确认',
          message: '退保后您将失去保障，您确定退保吗？',
          tips: '退保成功后您购买的保险金额将会在7个工作日之内原路退回您的账户',
          cancel_text: '取消退保',
          confirm_text: '确认退保',
          sign: 'revocation',
        },
      }
    },
    /**
     * 撤销/退保-保险-请求
     */
    revocationInsuranceRequest() {
      let {data} = this.myDialog;
      return this.myRequest({
        url: '/api/insurance/insuredordercancel',
        data: {
          id: data.id,
        }
      })
      // .then((res) => {
      //   console.log('撤销/退保-保险 res == ', res);

      // })
    },
    /**
     * 初始化对话框
     */
    initMyDialogFun() {
      this.myDialog = {
        data: {},
        visible: false,
        options: {},
      }
    },
    /**
     * 对话框回调
     */
    myDialogChangeFun(e) {
      console.log('对话框回调 e == ',e);
      let { visible, status, message, data, name, sign } = e;
      if(status == 1) { // 确认
        /**
         * 匹配与作用
         */
        this.matchingAndEffectFun(sign);
      } else { // 取消
        // 初始化对话框
        this.initMyDialogFun();
      }
    },
    /**
     * 匹配与作用
     */
    matchingAndEffectFun(sign) {
      switch(sign) {
        case 'revocation': // 撤销
            //  撤销/退保-保险-请求
            this.revocationInsuranceRequest().then((res) => {
              this.tableData.list = [];
              // 获取保单数据
              this.getPolicyDataFun();
              this.myMessage({
                type: 'success',
                message: '退保成功！'
              })
            }).catch((err) => {
              // // 初始化对话框
              // this.initMyDialogFun();
            });
          break;
        case 'delete': // 删除
            // 删除订单-请求
            this.delOrderRequest().then(res => {
              this.tableData.list = [];
              // 获取保单数据
              this.getPolicyDataFun();
              this.myMessage({
                type: 'success',
                message: res.data.msg
              })
            });
          break;
      }

      // 初始化对话框
      this.initMyDialogFun();
    },
    /**
     * 打开支付弹窗
     */
    openPayWindowFun(opt) {
      this.pay_config.price = opt.price || '';
      this.pay_config.qr_code = opt.qr_code;
      this.pay_config.visible = true;
    },
    /**
     * 支付结果回调
     */
    payResultChangeFun(e) {
      console.log('支付结果回调 e == ',e)
      let { visible, status, message, data } = e;
      this.pay_config.visible = visible;
      // 自定义清除定时器
      this._clearIntervalFun();
      // 是否支付成功
      if(status == 1) {
        // 发起录入后
        this.sendEnteringAfterFun();
      } else {
        this.myMessage({
          message: message,
        });
      }
    },
    /**
     * 自定义清除定时器
     */
    _clearIntervalFun(){
      if(this.pay_config.pay_timer) {
        clearInterval(this.pay_config.pay_timer);
        this.pay_config.pay_timer = null;
      }
    },
    /**
     * 申请发票
     */
    applyInvoiceFun(item) {
      console.log('申请发票 item == ',item)
      let { trade_policy_no } = item;
      if(!trade_policy_no){
        this.myMessage({
          message: '保单不存在',
        });
        return;
      }

      this.externalLink.options = item;
      console.log('申请发票 this.externalLink == ',this.externalLink)
      this.toogleExternalLinkDrawer();
    },
    /**
     * 外部链接抽屉-开/关
     */
    toogleExternalLinkDrawer(flag) {
      console.log('外部链接抽屉-开/关 flag == ',flag)
      if(String(flag) == 'false'){
        this.externalLink.visible = flag;
      } else {
        this.externalLink.visible = true;
      }
    },
    /**
     * 申请发票-two
     */
    applyInvoiceTwoFun(item) {
      console.log('申请发票 two item == ',item)
      let { id } = item;
      // 申请发票-two-请求
      this.applyInvoiceTwoRequest(id);
    },
    /**
     * 申请发票-two-请求
     */
    applyInvoiceTwoRequest(id) {
      this.myRequest({
        url: '/api/insurance/invoiceapply',
        data: {
          id: id,
        }
      }).then((res) => {
        console.log('申请发票-two-请求 res == ',res);
        let resData = res.data;
        let type = 'error';
        if(resData.msg.length == 4) {
          type = 'success';
        }
        this.myMessage({
          type: type,
          message: resData.msg,
        })
        this.tableData.list = [];
        // 获取保单数据
        this.getPolicyDataFun();
      })
    },
    /**
     * 下载-申请two的发票
     */
    downloadInvoiceTwoFun(item) {
      console.log('下载-申请two的发票 item == ',item)
      let { invoice_file } = item;
      console.log('下载-申请two的发票 invoice_file == ',invoice_file)
      window.open(invoice_file,'_blank');
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
@import '@/views/insurance/css/common.scss';
.done-container {
  box-sizing: border-box;
  padding-top: 13px;
}

.table-container {
  margin-top: 86px;
  .table-overview {
    .table-statistics {
      margin-top: 20px;
    }
    .table-paging {
      margin: 50px 0;
      text-align: center;
    }
  }
}

.details-form {
  display: flex;
  .df-item {
    margin-right: 35px;
    font-size: 16px;
    color: #787878;
  }
}

.simulate-table {
  box-sizing: border-box;
  border: 1px solid #F0F0F0;
  .st-empty {
    box-sizing: border-box;
    padding: 50px;
    text-align: center;
    .iconfont {
      font-size: 50px;
      color: #aaaaaa;
    }
  }
  .st-head .st-th,
  .st-body .st-td {
    box-sizing: border-box;
    padding: 8px 24px;
    flex: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #444343
  }
  .st-head {
    background: #F0F0F0;
    .st-th {
      height: 80px;
      font-size: 18px;
    }
  }
  .st-body {
    .st-td {
      height: 98px;
      font-size: 16px;
    }
    .tr-before {
      box-sizing: border-box;
      padding: 8px 24px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 14px;
      color: #787878;
    }
  }
  .st-item {
    border-top: 1px solid #e6e6e6;
    background: #fff;
    &:first-of-type {
      border-top: none;
    }
    &:hover {
      background: #f5f5f5;
    }
  }
  .st-tr {
    display: flex;
  }
}

.simulate-table {
  &.query-table {
    .st-head {}
    .st-body {}
    .st-th,.st-td {
      &:nth-child(1) {
        width: 250px;
      }
      &:nth-child(2){
        width: 200px;
        text-align: center;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: 110px;
        text-align: center;
      }
      &:nth-child(7) {
        width: 190px;
        text-align: center;
      }
    }
    .st-th {}
    .st-td {
      .control-p {
        color: #E62129;
        margin-top: 8px;
        &:first-of-type {
          margin-top: 0;
        }
        .fake-a {
          color: #E62129;
          cursor: pointer;
        }
      }
    }
    .tr-before {
      .details-form {
        display: flex;
        .df-item {
          margin-right: 68px;
          font-size: 14px;
          color: #787878;
        }
      }
    }
    .st-tr {
    }
    .single-failure {
      position: relative;
      .icon {
        z-index: 1;
        position: relative;
        top: -5px;
        right: -5px;
        .iconfont {
          font-size: 16px;
          color: #E62129;
        }
      }
    }
  }
}

.form-container {
  .form-module {
    .fm-itme {
      width: 33.33%;
    }
    .fm-rest {
      .fm-control {
        margin-top: 50px;
      }
    }
  }
}

</style>
