<template>
  <div class="dialog-container">
    <el-dialog
      width="736px"
      top="10vh"
      fullscreen
      center
      :visible.sync="mapVisible"
      :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="dialog-body">
        <div class="dialog-head">
          <div class="dialog-main">
            <div class="dialog-title">
              <h3 class="title-text">理赔详情</h3>
            </div>
          </div>
        </div>
        <div class="dialog-main">
          <div class="form-module margin-top">
            <div class="fm-tr">
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">出险人姓名：</div>
                </div>
                <div class="fm-val">
                  <div class="field-item">
                    <div class="input-box">
                      <el-input v-model="formData.name" placeholder="请输入" @input="inputChangeFun($event,'name')"></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">出险人证件类型：</div>
                </div>
                <div class="fm-val">
                  <div class="select-box">
                    <el-select v-model="formData.certificate_type" placeholder="请选择">
                      <el-option label="身份证" value="1"></el-option>
                      <el-option label="护照" value="2"></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">出险人证件号：</div>
                </div>
                <div class="fm-val">
                  <div class="field-item">
                    <div class="input-box">
                      <el-input v-model="formData.certificate_code" placeholder="请输入" @input="inputChangeFun($event,'certificate_code')"></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">出险人电话：</div>
                </div>
                <div class="fm-val">
                  <div class="field-item">
                    <div class="input-box">
                      <el-input v-model="formData.tel" maxlength="11" placeholder="请输入" @input="inputChangeFun($event,'tel')"></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">出险时间：</div>
                </div>
                <div class="fm-val">
                  <div class="picker-date">
                    <el-date-picker
                      v-model="formData.accident_time"
                      type="datetime"
                      placeholder="请选择"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    <!-- @change="function(e) {formData.accident_time = e;}" -->
                    </el-date-picker>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">后续人联系姓名：</div>
                </div>
                <div class="fm-val">
                  <div class="field-item">
                    <div class="input-box">
                      <el-input v-model="formData.contact_name" placeholder="请输入" @input="inputChangeFun($event,'contact_name')"></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">后续人联系电话：</div>
                </div>
                <div class="fm-val">
                  <div class="field-item">
                    <div class="input-box">
                      <el-input v-model="formData.contact_tel" maxlength="11" placeholder="请输入" @input="inputChangeFun($event,'contact_tel')"></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">后续人联系邮箱：</div>
                </div>
                <div class="fm-val">
                  <div class="field-item">
                    <div class="input-box">
                      <el-input v-model="formData.contact_email" placeholder="请输入后续人联系邮箱" @input="inputChangeFun($event,'contact_email')"></el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fm-itme">
                <div class="fm-key">
                  <div class="key-title required-style">保单号：</div>
                </div>
                <div class="fm-val">
                  <div class="field-item">
                    <div class="input-box">
                      <el-autocomplete
                        v-model="formData.trade_policy_no"
                        :class="{'isIphone8Up': facility.ios_grade > 2}"
                        :fetch-suggestions="function(e,cb) { cb(inputAdviceQuerySearch(e,'trade_policy_no'))}"
                        placeholder="请输入"
                        @select="function(item){ formData.trade_policy_no = item['trade_policy_no']}">
                        <template slot-scope="{ item }">
                          <div>{{ item.trade_policy_no }}</div>
                        </template>
                      </el-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-module">
            <div class="fm-itme width-aotu">
              <div class="fm-key">
                <div class="key-title required-style">简述受伤经过：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box textarea-style">
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="formData.describe"
                      placeholder="请简述受伤经过"
                      @input="inputChangeFun($event,'describe')">
                    </el-input>
                    <!-- <div class="limit-to">{{formData.content_msg.length}}/200</div> -->
                  </div>
                </div>
                <div class="fm-ensample">填写样例：xxxx年xx月xx日上/下午xx时左右，在xx省xx市xx街道xx号，因为xx原因，导致xxx伤害，已至xx医院（三甲公立医院）就诊，医生诊断为xxxx。</div>
              </div>
            </div>
          </div>
          <div class="form-module">
            <div class="fm-rest">
              <div class="fm-control">
                <div class="control-item">
                  <el-button class="fake-btn" @click="clickSubmitBtnFun">
                    <span>提交</span>
                  </el-button>
                </div>
              </div>
              <div class="fm-tips">注：以上内容全为必填项，涉及后续理赔问题，请认真填写。</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 添加人员
export default {
  // 允许组件模板递归地调用自身
  name: 'add-personnel-dialog',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      facility: {}, // 当前设备
      is_submit: false, // 是否在提交
      mapVisible: this.visible,
      inputHistoryData: { // 历史输入数据
        allPolicy: [], // 所有保单
      },
      formData: {
        name: '', //	是	string	出险人 - 姓名
        certificate_type: '', //	是	string	出险人 - 证件类型【1身份证，2护照】
        certificate_code: '', //	是	string	出险人 - 证件号码
        tel: '', //	否	string	出险人 - 电话
        accident_time: '', //	否	string	出险人 - 出险时间（格式：yyyy-mm-dd hh:ii:ss）
        contact_name: '', //	是	string	后续联系人 - 姓名
        contact_tel: '', //	是	string	后续联系人 - 电话
        contact_email: '', //	是	string	后续联系人 - 邮箱
        describe: '', //	是	string	受伤经过描述
        trade_policy_no: '', // 保单号
      },
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    visible: function(e) {
      this.mapVisible = e;
      // 初始化页面 data 数据
      this.initPageDataFun();
    }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 获取设备信息
      this.getFacilityInfo();
      // 获取有效保单
      this.getEffectivePolicyFun();
    },
    /**
     * 获取设备信息
     */
    getFacilityInfo() {
      let facility = this.myGetCurrentFacility();
      console.log('获取设备信息 facility == ',facility)
      this.facility = facility;
    },
    // 初始化页面 data 数据
    initPageDataFun() {
      this.is_submit = false; // 是否在提交
      // 初始化表单数据
      this.initFormDataFun();
    },
    /**
     * 初始化表单数据
     */
    initFormDataFun() {
      let formData = {
        name: '', //	是	string	出险人 - 姓名
        certificate_type: '', //	是	string	出险人 - 证件类型【1身份证，2护照】
        certificate_code: '', //	是	string	出险人 - 证件号码
        tel: '', //	否	string	出险人 - 电话
        accident_time: '', //	否	string	出险人 - 出险时间（格式：yyyy-mm-dd hh:ii:ss）
        contact_name: '', //	是	string	后续联系人 - 姓名
        contact_tel: '', //	是	string	后续联系人 - 电话
        contact_email: '', //	是	string	后续联系人 - 邮箱
        describe: '', //	是	string	受伤经过描述
        trade_policy_no: '', // 保单号
      };

      this.formData = formData;
    },
    /**
     * 历史输入-输入建议
     */
    inputAdviceQuerySearch(queryString,key) {
      var data = this.inputHistoryData.allPolicy;
      var results = queryString ? data.filter(this.createFilter(queryString,key)) : data;
      return results;
    },
    /**
     * 历史输入-输入建议-过滤
     */
    createFilter(queryString,key) {
      return (data) => {
        return (data[key].toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    /**
     * 初始化表单数据
     */
    initFormDataFun2() {
      let formData = {
        name: '安格', //	是	string	出险人 - 姓名
        certificate_type: '1', //	是	string	出险人 - 证件类型【1身份证，2护照】
        certificate_code: '110101199003070177', //	是	string	出险人 - 证件号码
        tel: '13845645624', //	否	string	出险人 - 电话
        accident_time: '2021-12-17 00:00:00', //	否	string	出险人 - 出险时间（格式：yyyy-mm-dd hh:ii:ss）
        contact_name: '何格', //	是	string	后续联系人 - 姓名
        contact_tel: '13845684524', //	是	string	后续联系人 - 电话
        contact_email: '123abc@163.com', //	是	string	后续联系人 - 邮箱
        describe: '就这?!', //	是	string	受伤经过描述
        trade_policy_no: '21010000894210001493', // 保单号
      };

      this.formData = formData;
    },
    /**
     * 输入框监听变化
     */
    inputChangeFun(event,name) {
      console.log(event)
      console.log(name)
      console.log(this.formData)
    },
    /**
     * 关闭弹窗前
     */
    dialogBeforeCloseFun(done) {
      done();
      console.log('关闭弹窗前 == ',this.mapVisible)
      // 告知弹窗变化
      this.informChangeFun();
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      let default_data = {
        visible: false,
        status: 3, // 1/成功 2/失败 3/取消
        message: '取消',
        data: '',
      };
      let result = Object.assign(default_data,opt);
      this.$emit('on-change',result)
    },
    /**
     * 类别变换
     */
    categoryChangeFun(e) {
      console.log('类别变换 == ',e)
      console.log('类别变换 this.category_options == ',this.category_options)
    },
    /**
     * 获取有效保单
     */
    getEffectivePolicyFun() {
      this.myRequest({
        url: '/api/insurance/effectiveinsuredorderall',
        data: {
          trede_policy_no: '',
        }
      }).then(res => {
        console.log('获取有效保单 res == ',res);
        let resData = res.data.data;
        this.inputHistoryData.allPolicy = resData;
      })
    },
    /**
     * 点击提交按钮
     */
    clickSubmitBtnFun() {
      console.log('点击提交按钮')

      // 校验数据
      let flag = this.verifyDataFun();
      if(!flag) return;

      // 锁(不能连续点点击)
      if(this.is_submit) return;
      this.is_submit = true;


      // 发起录入请求
      this.sendEnteringRequestFun().then((res) => {
        let resData = res.data.data;
        console.log('录入请求成功 res == ',res);
        // 发起录入后
        this.sendEnteringAfterFun();
      }).catch((err) => {
        console.log('录入请求失败 err == ',err)
        // 锁
        this.is_submit = false;
      });
    },
    /**
     * 发起录入请求
     */
    sendEnteringRequestFun(formData) {
      const _this = this;
      formData = formData || this.formData;
      console.log('发起录入请求 查看业务 formData == ',formData);
      return this.myRequest({
        method: 'post',
        url: '/api/insurance/compensationsave',
        data: formData,
      })
      // .then((res) => {
      //   let resData = res.data.data;
      //   console.log('录入请求成功 res == ',res);
      //   // this.mySetStorage({ // 设置本地缓存
      //   //   name: 'active_test',
      //   //   value: this.formData,
      //   //   expires: 60000 * 24 * 31
      //   // });
      //   // 发起录入后
      //   this.sendEnteringAfterFun();
      // }).catch((err) => {
      //   console.log('录入请求失败 err == ',err)
      //   // 锁
      //   this.is_submit = false;
      // })
    },
    /**
     * 发起录入后
     */
    sendEnteringAfterFun(data) {
      let message = '提交成功！';
      // 如果有id，则为修改
      if(this.formData.id){
        message = '修改成功！';
      }
      this.myMessage({
        type: 'success',
        message: message,
      })
      // 告知弹窗变化
      this.informChangeFun({
        visible: false,
        status: 1, // 1/成功 2/失败 3/取消
        message: '成功',
        data: '',
      });
      // 初始化页面 data 数据
      this.initPageDataFun();
    },
    /**
     * 校验数据
     */
    verifyDataFun() {
      let form_data = this.formData;
      console.log('form_data == ',form_data)

      // formData: {
      //    name: '', //	是	string	出险人 - 姓名
      //    certificate_type: '', //	是	string	出险人 - 证件类型【1身份证，2护照】
      //    certificate_code: '', //	是	string	出险人 - 证件号码
      //    tel: '', //	否	string	出险人 - 电话
      //    accident_time: '', //	否	string	出险人 - 出险时间（格式：yyyy-mm-dd hh:ii:ss）
      //    contact_name: '', //	是	string	后续联系人 - 姓名
      //    contact_tel: '', //	是	string	后续联系人 - 电话
      //    contact_email: '', //	是	string	后续联系人 - 邮箱
      //    describe: '', //	是	string	受伤经过描述
      //    trade_policy_no: '21010000894210001493', // 保单号
      // },

      // 名称
      if(!form_data.name) {
        this.myMessage({
          message: '请输入出险人名称!'
        });
        return false;
      }

      // 证件类型
      if(!form_data.certificate_type) {
        this.myMessage({
          message: '请输入选择出险人证件类型！'
        });
        return false;
      }

      // 证件号码
      if(!form_data.certificate_code) {
        this.myMessage({
          message: '请输入出险人证件号码!'
        });
        return false;
      } else {
        if(form_data.certificate_type == 1) {
          if(!this.myChecktool.identityCodeValid2(form_data.certificate_code)) {
            this.myMessage({
              message: '请输入正确格式的身份证号码!'
            });
            return false;
          }
        } else if(form_data.certificate_type == 2){
          if(!this.REGEXP.is_passport.test(form_data.certificate_code)) {
            this.myMessage({
              message: '请输入正确格式的护照!'
            });
            return false;
          }
        }
      }

      // 电话
      if(!form_data.tel) {
        this.myMessage({
          message: '请输入出险人的电话号码!'
        });
        return false;
      } else if(!this.REGEXP.is_phone.test(form_data.tel)) {
        this.myMessage({
          message: '请输入出险人有效的电话号码!'
        });
        return false;
      }

      // 出险时间
      if(!form_data.accident_time) {
        this.myMessage({
          message: '请选择出险时间！'
        });
        return false;
      }

      // 后续人联系姓名
      if(!form_data.contact_name) {
        this.myMessage({
          message: '请输入后续人联系姓名！'
        });
        return false;
      }

      // 后续人联系电话
      if(!form_data.contact_tel ) {
        this.myMessage({
          message: '请输入后续人联系电话!'
        });
        return false;
      } else if(!this.REGEXP.is_phone.test(form_data.contact_tel)) {
        this.myMessage({
          message: '请输入后续人联系的有效电话号码!'
        });
        return false;
      }

      // 电子邮箱
      if(!form_data.contact_email) {
        this.myMessage({
          message: '请输入后续人联系的电子邮箱!'
        });
        return false;
      } else if(!this.REGEXP.is_email.test(form_data.contact_email)) {
        this.myMessage({
          message: '请输入正确格式的电子邮箱!'
        });
        return false;
      }

      // 保单号
      if(!form_data.trade_policy_no) {
        this.myMessage({
          message: '请输入保单号！'
        });
        return false;
      }

      // 受伤经过
      if(!form_data.describe) {
        this.myMessage({
          message: '请简述受伤经过！'
        });
        return false;
      }

      return true;
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 获取页面数据
    this.getPageDataFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
@import '@/views/insurance/css/common.scss';
.dialog-container {
  box-sizing: border-box;
  &::v-deep {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__headerbtn {
      width: 24px;
      height: 24px;
      text-align: center;
      font-size: 24px;
      top: 24px;
    }
  }
}

.dialog-body {
  .dialog-head {
    height: 80px;
    line-height: 80px;
    border-bottom: 7px solid #F5F5F5;
    .dialog-title {
      display: flex;
      align-items: center;
      .title-text {
        font-weight: 400;
        font-size: 18px;
        color: #444343;
      }
    }
  }
  .dialog-main {
    box-sizing: border-box;
    padding: 0 $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;
    .form-module {
      &.margin-top {
        margin-top: 20px;
      }
      .fm-itme {
        width: 33.33%;
        &.width-aotu {
          width: auto;
          .fm-val {
            .field-item .input-box {
              border: none;
              max-width: none;
              width: auto;
              height: auto;
            }
          }
        }
        // &:first-of-type{
        //   margin-top: 45px;
        // }
        .fm-val {
          .field-item .input-box {

          }
          .cascader-box {
            max-width: 353px;
          }
          .fm-ensample {
            margin-top: 17px;
            font-size: 14px;
            color: #787878;
          }
        }
      }
      .fm-rest {
        .fm-control {
          margin-top: 133px;
        }
        .fm-tips {
          margin-top: 20px;
          font-size: 14px;
          color: #E62129;
        }
      }
    }
  }
}
</style>
