<template>
  <div class="query-container">
    <div class="query-tab">
      <div class="qt-body">
        <div class="qt-module">
          <el-tabs v-model="editableTabsValue" @tab-click="tabClickFun">
            <!-- <template v-for="(item) in editableTabs">
              <el-tab-pane
                :key="item.name"
                :name="item.name"
              >
                <span slot="label">{{item.title}} <span class="label-unit">({{item.total}})</span></span>
              </el-tab-pane>
            </template> -->
            <el-tab-pane name="1">
              <span slot="label">已完成<span class="label-unit">({{policyData.list_total.finish}})</span></span>
            </el-tab-pane>
            <el-tab-pane name="0">
              <span slot="label">待投保<span class="label-unit">({{policyData.list_total.unfinished}})</span></span>
            </el-tab-pane>
            <el-tab-pane name="2">
              <span slot="label">已退保<span class="label-unit">({{policyData.list_total.refund}})</span></span>
            </el-tab-pane>
            <el-tab-pane name="3">
              <span slot="label">投保失败<span class="label-unit">({{policyData.list_total.fail}})</span></span>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="query-body">
      <keep-alive>
        <component :is="page_temp" :sign="sign" :type="editableTabsValue" @on-change="componentChangeFun"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import done from '@/views/insurance/child-pages/query/child/Done.vue';
export default {
  // 允许组件模板递归地调用自身
  name: 'Query',
  // 声明一组可用于组件实例中的组件
  components: {
    'done': done,
  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      policyData: {
        list_total: {}
      }, // 保单数据
      sign: 1, // 组件标识
      current: 1, // 当前下标
      page_temp: 'done',
      editableTabsValue: '1',
      editableTabs: [{
        title: '已完成',
        name: '1',
        total: 0,
      },{
        title: '待投保',
        name: '0',
        total: 0,
      },{
        title: '已退保',
        name: '2',
        total: 0,
      },{
        title: '投保失败',
        name: '3',
        total: 0,
      },],
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 组件传递数据接收
     */
    componentChangeFun(e) {
      console.log('组件传递数据接收 == ',e)
      this.policyData = e;
      // 初始化 tabs 选项
      this.inittabsOptions(e);

      this.initTabFun();
    },
    /**
     * 初始化 tabs 选项
     */
    inittabsOptions(opt) {
      opt = opt || this.policyData;
      let { finish = 0, refund = 0, unfinished = 0, fail = 0 } = opt.list_total;

      let tabs = [{
        title: '已完成',
        name: '1',
        total: finish,
      },{
        title: '待投保',
        name: '0',
        total: unfinished,
      },{
        title: '已退保',
        name: '2',
        total: refund,
      },{
        title: '出单失败',
        name: '3',
        total: fail,
      },];

      this.editableTabs = tabs;
    },
    /**
     * 初始化 tab
     */
    initTabFun() {

      let session = this.myGetSession(this.$options.name);
      console.log('session == ',session);
      if(session || parseInt(session) == parseInt(0)) {
      console.log('session e == ',session);
        this.editableTabsValue = String(session);
      } else {
        this.editableTabsValue = String(1);
      }
      console.log('this.editableTabsValue == ',this.editableTabsValue);

      // 切换视图面板
      // this.switchTabViewFun(this.editableTabsValue);
    },
    /**
     * tab 点击
     */
    tabClickFun(e) {
      console.log('e == ',e)
      console.log('e.name == ',e.name)
      let { name } = e;
      this.editableTabs = name;
      // 设置当前页面缓存
      this.setAtPageSessionFun(name);
      // // 切换视图面板
      // this.switchTabViewFun(name);
    },
    /**
     * 设置当前页面缓存
     */
    setAtPageSessionFun(val){
      this.mySetSession(this.$options.name,val)
    },
    /**
     * 移除当前页面缓存
     */
    removeAtPageSessionFun(){
      this.myRemoveSession(this.$options.name)
    },
    /**
     * 切换视图面板
     */
    switchTabViewFun(index) {
      index = Number(index || 0);
      console.log('视图面板 index == ',index)
      this.current = index;
      this.sign = index;
      switch(index) {
        case 1:
            this.page_temp = 'done';
          break;
        case 2:
            this.page_temp = 'site';
          break;
        case 3:
        default:
            this.page_temp = 'coach';
          break;
      }
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {
    // 初始化 tab
    this.initTabFun();
  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前
    // 移除当前页面缓存
    this.removeAtPageSessionFun();
  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
@import '@/views/insurance/css/common.scss';
.query-container {
  box-sizing: border-box;
}
.query-tab {
  border-bottom: 1px solid #E6E6E6;
  .qt-body {
    box-sizing: border-box;
    padding: 0 $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;
  }
  .qt-module {
    .label-unit {
      color: #E62129
    }
    &::v-deep {
      .el-tabs__header {
        margin: 0;
      }
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-tabs__item {
        $height: 10px;
        box-sizing: border-box;
        padding-top:  $height;
        padding-bottom:  $height;
        height: auto;
        font-size: 18px;
        color: #444343;
        &.is-active {
          color: #E62129;
        }
      }
      .el-tabs__active-bar {
        background: #E62129;
      }
    }
  }
}

.query-body {
  box-sizing: border-box;
  padding: 0 $theme-widht-whiteedge;
  // width: $theme-view-widht;
  max-width: $theme-view-widht;
  min-width: $theme-view-min-widht;
  margin: 0 auto;
}
</style>
