<template>
  <div class="container">
    <div class="block-module">
      <div class="tab-header">
        <tabs-tmpe ref="tabs" type="tabs" :current='tabsCurrent' @on-change="tabsTmpeChangeFun" />
      </div>
    </div>

    <div class="block-module not-border-bottom">
      <keep-alive>
        <component :is="page_temp" :sign="sign"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
  import insuranceList from '@/views/insurance/child-pages/insurance-list/InsuranceList.vue';
  import tabsTmpe from '@/views/insurance/components/tabs-temp/tabs-temp.vue';
  import qurey from '@/views/insurance/child-pages/query/Query.vue';
  import SubAccount from '@/views/insurance/child-pages/sub-account/SubAccount.vue';
  // import AccountAdmin from '@/views/insurance/child-pages/account-admin/AccountAdmin.vue';
  import ClaimsRecords from '@/views/insurance/child-pages/claims-records/ClaimsRecords.vue';
  import wallet from '@/views/insurance/child-pages/wallet/Wallet.vue';
  export default {
    // 允许组件模板递归地调用自身
    name: 'Insurance',
    // 声明一组可用于组件实例中的组件
    components: {
      tabsTmpe,
      'insurance-list': insuranceList,
      'qurey': qurey,
      'sub-account': SubAccount,
      // 'account-admin': AccountAdmin,
      'claims-records': ClaimsRecords,
      'wallet': wallet,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        session_name_two: 'session_name_two',
        ossUrl: this.GLOBAL.ossUrl,
        page_tabs_type: 'link', // tabs/切换模式 link/跳转模式
        tabsCurrent: '1', // tabs当前下标
        sign: 1, // 组件标识
        current: 1, // 当前下标
        page_temp: 'insurance-list',
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 设置当前页面缓存
       */
      setAtPageSessionFun(name, val) {
        name = name || this.$options.name;
        this.mySetSession(name, val)
      },
      /**
       * 获取当前页面缓存
       */
      getAtPageSessionFun(name, callback) {
        name = name || this.$options.name;
        let result = this.myGetSession(name) || 'link';
        if (callback) {
          callback(result);
        }
        return result;
      },
      /**
       * 移除当前页面缓存
       */
      removeAtPageSessionFun(name) {
        console.log('移除当前页面缓存 name == ', name)
        return new Promise(resolve => {
          name = name || this.$options.name;
          this.myRemoveSession(name);
          resolve();
        })
      },
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取 tabs
        // this.getTabsNameFun();

        // 获取页面路由 id
        this.getPagePathIdFun().then((id) => {

          if (id) {
            // 设置当前页面缓存
            this.setAtPageSessionFun(this.session_name_two, 'link');
            console.log('初始化 id == ', id);
            id = this.REGEXP.is_number.test(id) ? id : 1;
            id = id > 5 || id < 1 ? 1 : id;
            console.log('get id  == ', id);
          } else {
            // // 设置当前页面缓存
            // this.setAtPageSessionFun(false,'tabs');
            // this.setAtPageSessionFun(this.session_name_two,'tabs');
          }
          // 获取 tabs
          this.getTabsNameFun(id);
        })
      },
      /**
       * 获取页面tabs类型
       */
      getPageTabsTypeFun() {
        return new Promise((resolve, reject) => {
          let session = this.getAtPageSessionFun(this.$options.name); // 获取当前页面缓存
          console.log('获取页面tabs类型 session == ', session)
          if (session) {
            this.page_tabs_type = session;
          }
          //  else {
          //   this.page_tabs_type = 'tabs';
          // }
          resolve(session);
        })
      },
      /**
       * 获取页面路由 id
       */
      getPagePathIdFun() {
        return new Promise((resolve, reject) => {
          // 获取当前路由
          this.myGetRouter().then((res) => {
            console.log(res)
            let {
              id
            } = res.query;
            resolve(id);
          });
        })
      },
      /**
       * 获取 tabs
       */
      getTabsNameFun(id) {
        // if(id) return this.switchTabViewFun(id);// 切换视图面板
        if (!this.$refs.tabs) return;
        this.$refs.tabs.getTabsNameFun((res) => {
          console.log(' 获取 tabs res == ', res);
          let {
            name
          } = res;
          // 获取页面tabs类型
          this.getPageTabsTypeFun().then((session) => {
            // // 切换视图面板
            // this.switchTabViewFun(name);
            console.log(' 获取 id == ', id);
            console.log(' 获取 name == ', name);
            console.log(' 获取 session == ', session);

            if (session == 'tabs' || !id) {
              this.switchTabViewFun(name); // 切换视图面板
            } else {
              this.switchTabViewFun(id); // 切换视图面板
            }
          })
        })
      },
      /**
       * tags 变化
       */
      tabsTmpeChangeFun(e) {
        console.log('tags 变化 e ==', e)
        let {
          visible,
          status,
          message,
          data
        } = e;
        if (status == 1) {
          // 设置当前页面缓存
          this.setAtPageSessionFun(false, 'tabs');
          // 设置当前页面缓存
          // this.setAtPageSessionFun(this.session_name_two,'tabs');
          // 切换视图面板
          this.switchTabViewFun(data);
        }
      },
      /**
       * 切换视图面板
       */
      switchTabViewFun(index) {
        index = Number(index || 0);
        console.log('视图面板 index == ', index)
        this.current = index;
        this.sign = index;
        this.tabsCurrent = index; // tabs当前下标
        switch (index) {
          case 1:
            this.page_temp = 'insurance-list';
            break;
          case 2:
            this.page_temp = 'qurey';
            break;
          case 3:
            this.page_temp = 'claims-records';
            break;
            // case 4:
            //   this.page_temp = 'sub-account';
            //   // this.page_temp = 'account-admin';
            //   break;
          case 4:
            this.page_temp = 'wallet';
            break;

            // default:
            //     this.page_temp = 'insurance-list';
            //   break;
        }
        console.log('视图面板 this.page_temp == ', this.page_temp)
        console.log('视图面板 this.tabsCurrent == ', this.tabsCurrent)
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前
      // 移除当前页面缓存
      this.removeAtPageSessionFun(this.session_name_two).then(res => {
        // 获取当前页面缓存
        let session_two = this.getAtPageSessionFun(this.session_name_two);
        console.log('移除当前页面缓存 session_two == ', session_two)
        // 设置当前页面缓存
        this.setAtPageSessionFun(false, session_two);
        // this.setAtPageSessionFun(false,'link');
      });
    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .container {
    box-sizing: border-box;
  }

  .admin-tab {
    box-sizing: border-box;
    padding: 10px $theme-widht-whiteedge;
    // width: $theme-view-widht;
    max-width: $theme-view-widht;
    min-width: $theme-view-min-widht;
    margin: 0 auto;

    .at-module {
      &::v-deep {
        .el-tabs__header {
          margin: 0;
        }

        .el-tabs__nav-wrap::after {
          display: none;
        }

        .el-tabs__item {
          $height: 5px;
          // padding-top:  $height;
          // padding-bottom:  $height;
          height: auto;
          font-size: 18px;
          color: #444343;

          &.is-active {
            color: #409EFF;
          }
        }
      }
    }
  }

</style>
