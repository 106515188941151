<template>
  <div class="simulate-table">
    <div class="st-head">
      <div class="st-tr">
        <div class="st-th">head</div>
        <div class="st-th">head2</div>
      </div>
    </div>
    <div class="st-body">
      <div class="st-item">
        <div class="tr-before">
          <slot name="body-before"></slot>
        </div>
        <div class="st-tr">
          <div class="st-td"><slot></slot></div>
        </div>
        <div class="tr-after">
          <slot name="body-after"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {

    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {

  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
}

.simulate-table {
  .st-head {
    .st-th {}
  }
  .st-body {
    .st-td {}
  }
  .st-head .st-th,
  .st-body .st-td {
    box-sizing: border-box;
    padding: 8px;
    flex: 1;
  }
  .st-tr {
    display: flex;
  }
}
</style>
