<template>
  <div class="container">
    <el-drawer
      title="申请发票"
      size="998px"
      :visible.sync="mapVisible"
      direction="rtl"
      :before-close="dialogBeforeCloseFun">
      <div class="place">
        <div class="place-head" v-if="mapOptions.trade_policy_no">
          <ul class="info-list">
            <li class="il-item">
              <div class="il-line">
                <div class="il-details">
                  <span class="il-key">保单号：</span>
                  <span class="il-val">{{mapOptions.trade_policy_no}}</span>
                </div>
                <div class="il-control" @click="doCopy(mapOptions.trade_policy_no)">
                  <span>复制</span>
                  <i class="iconfont icon-fuzhi1"></i>
                </div>
              </div>
              <div class="il-line">
                <div class="il-details">
                  <span class="il-key">证件号：</span>
                  <span class="il-val">{{mapOptions.person.certificate_code}}</span>
                </div>
                <div class="il-control" @click="doCopy(mapOptions.person.certificate_code)">
                  <span>复制</span>
                  <i class="iconfont icon-fuzhi1"></i>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="place-body">
          <!-- <iframe
            class="iframe-box"
            src="https://property.pingan.com/kehufuwu/policyEnquriy/self-service_invoicing.shtml"
          ></iframe> -->
           <iframe
            class="iframe-box"
            v-if="mapOptions.trade_policy_no"
            :src="mapOptions.insurance_data.invoice_link"
          ></iframe>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: '',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: {}
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      mapVisible: this.visible,
      mapOptions: this.options,
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {
    visible: function(e) {
      this.mapVisible = e;
    },
    options: function(e) {
      console.log('this.mapOptions e == ',e)
      this.mapOptions = e;
      console.log('this.mapOptions == ',this.mapOptions)
    }
  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 关闭弹窗前
     */
    dialogBeforeCloseFun(done) {
      done();
      console.log('关闭弹窗前 == ',this.mapVisible)
      // 告知弹窗变化
      this.informChangeFun();
    },
    /**
     * 告知弹窗变化
     */
    informChangeFun(opt) {
      let default_data = {
        visible: false,
        status: 3, // 1/成功 2/取消 3/取消，关闭弹窗
        message: '取消，关闭弹窗',
        data: '',
      };
      let result = Object.assign(default_data,opt);
      this.$emit('on-change',result)
    },
    /**
     * 复制
     */
    doCopy: function (value) {
      const _this = this;
      this.$copyText(value).then(function (e) {
        console.log('doCopy 复制成功 e == ',e);
        _this.myMessage({
          type: 'success',
          message: '链接复制成功',
        });
      }, function (e) {
        console.log('doCopy 链接失败 e == ',e);
        _this.myMessage({
          type: 'error',
          message: '链接复制失败',
        });
      })
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  &::v-deep {
    .el-drawer.rtl {
      right: 50%;
      transform: translateX(50%);
      animation: initial;
    }
    .el-drawer__open .el-drawer.rtl {
      animation: initial;
    }
  }
}
.place {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .place-head {
    box-sizing: border-box;
    padding: 0 20px;
    margin-bottom: 32px;
    text-align: center;
    .info-list {
      display: inline-block;
      .il-item {
        .il-line {
          display: flex;
          // align-items: center;
          height: 24px;
          line-height: 24px;
          margin: 4px 0;
          font-size: 16px;
          .il-details {
            .il-key {
              color: #444434;
            }
            .il-val {
              color: #787878;
            }
          }
          .il-control {
            margin-left: 10px;
            font-size: 12px;
            color: #999;
            &:hover {
              color: #E62129;
            }
            .iconfont {
              margin-left: 2px;
              font-size: 12px;
            }
            & > span, & > i {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .place-body {
    flex: 1;
    min-height: 0;
    width: 100%;
    .iframe-box {
      width: 100%;
      height: 100%;
      border: none;
      background: #fff;
    }
  }
}
</style>
