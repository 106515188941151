<template>
  <div class="container">
    <div class="account-recharge">
      <div class="ar-title">
        <span class="title-text">提现至：</span>
        <span class="title-text">微信零钱（24小时到账）</span>
      </div>
      <div class="ar-price">
        <div class="price-place">
          <span class="price-key">账户余额：</span>
          <span class="price-value">
            <i class="iconfont icon-jiage"></i>
            <span class="val-text" v-if="walletBalanceConfig">{{walletBalanceConfig.user_wallet.normal_money}}</span>
          </span>
        </div>
      </div>
      <div class="ar-form">
        <div class="af-item">
          <div class="af-field">
            <el-input placeholder="请输入提现金额" v-model="input_price">
              <i slot="prefix" class="iconfont icon-jiage"></i>
            </el-input>
          </div>
          <div class="af-control">
            <div class="control-item">
              <el-button class="fake-button" @click="confirmClick">
                <span class="btn-text">确认提现</span>
              </el-button>
            </div>
            <div class="control-item all-withdrawal">
              <span class="btn-text" @click="allMoneyClick">全部提现</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    // 允许组件模板递归地调用自身
    name: 'balance-withdrawal',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        input_price: '', // 输入金额
        walletBalanceConfig: null, // 钱包余额配置
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 初始化页面数据
        this.initPageDataFun();
        // 获取明细
        this.getWalletBalanceFun();
      },
      /**
       * 初始化页面数据
       */
      initPageDataFun() {
        this.input_price = ''; // 输入金额
        this.walletBalanceConfig = null; // 钱包余额配置
      },
      /**
       * 获取-钱包余额
       */
      getWalletBalanceFun() {
        this.walletBalanceConfig = null;
        this.myRequest({
            url: '/api/plugins/index&pluginsname=wallet&pluginscontrol=user&pluginsaction=index',
            data: {},
          })
          .then((res) => {
            console.log('钱包余额 res == ', res)
            let resData = res.data.data;
            this.walletBalanceConfig = resData;
            console.log('钱包余额 this.walletBalanceConfig == ', this.walletBalanceConfig)
          }).catch((err) => {
            console.log('钱包余额 err == ', err)
          })
      },
      /**
       * 全部提现点击事件
       */
      allMoneyClick() {
        this.input_price = this.walletBalanceConfig.user_wallet.normal_money;
      },
      //表单验证
      formVerify() {
        let {
          input_price
        } = this;

        if (!this.REGEXP.is_floating.test(input_price)) {
          this.myMessage({
            message: '请输入有效额度，可含小数点后两位!',
          });
          return false;
        }

        if (input_price == 0) {
          this.myMessage({
            message: '充值金额不能为0!',
          });
          return false;
        }

        if (input_price > this.walletBalanceConfig.user_wallet.normal_money) {
          this.setData({
            formError: '提现金额不能大于可提现金额!'
          })
          return;
        }

        return true;
      },
      /**
       * 确认-点击事件
       */
      confirmClick() {
        // 表单校验
        let flag = this.formVerify();
        if (!flag) return;
        console.log('请求参数1 == ', this.input_price)

        // 提现-函数
        this.withdrawDepositFun().then((res) => {
          // 发起录入后
          this.sendEnteringAfterFun();
        });
      },
      /**
       * 提现-函数
       */
      withdrawDepositFun() {
        return new Promise((resolve, reject) => {
          this.myRequest({
              url: '/api/plugins/index&pluginsname=wallet&pluginscontrol=cash&pluginsaction=create',
              data: {
                money: this.input_price,
              },
            })
            .then((res) => {
              console.log('提现-函数 res == ', res)
              if (res.data.code != 0) {
                this.myMessage({
                  message: res.data.msg,
                });
                return;
              }
              resolve(res);
            }).catch((err) => {
              console.log('提现-函数 err == ', err)
            })
        })
      },
      /**
       * 发起录入后
       */
      sendEnteringAfterFun(data) {
        // 获取页面数据
        this.getPageDataFun();
        // 跳转参数
        let skip_qurey = {
          status: '1',
          status_msg: '提现申请成功！',
          text_list: [], // 文本
          source: 'insurance', // 来源
        };

        // 跳转路由
        this.myRouterPush({
          path: '/succeed',
          query: {
            data: encodeURIComponent(JSON.stringify(skip_qurey)),
          }
        });
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
  }

  .account-recharge {
    box-sizing: border-box;
    padding: 30px 35px;
    background: #fff;

    .ar-title {
      font-size: 18px;
      color: #787878;

      .title-text {
        &.diff-color {
          color: #444343;
        }
      }
    }

    .ar-price {
      margin: 24px 0;

      .price-place {
        display: inline-block;
        box-sizing: border-box;
        // padding: 6px 24px;
        border-radius: 10px;
        // background: #FFF4DB;

        .price-key {
          font-size: 20px;
          color: #444343;
        }

        .price-value {
          font-weight: 700;
          font-size: 28px;
          // color: #D42A20;
          color: #444343;

          .val-text {
            position: relative;
            top: 2px;
          }

          .iconfont {
            line-height: 40px;

            &.icon-jiage {
              font-size: 20px;
            }
          }
        }
      }
    }

    .ar-form {

      .af-item {
        display: flex;

        .af-field {
          .iconfont {
            line-height: 40px;
            position: relative;
            left: 4px;
            // color: #D42A20;
            color: #444343;

            &.icon-jiage {}
          }

          &::v-deep {

            .el-input.is-active,
            .el-input__inner:focus {
              border-color: #DCDFE6;
            }
          }
        }

        .af-control {
          display: flex;
          align-items: center;

          .control-item {
            margin-left: 14px;

            .fake-button {
              color: #fff;
              background: #D42A20;
              cursor: pointer;
            }

            &::v-deep {
              .el-button {
                border-color: #D42A20;
                background: #D42A20;
              }

              .el-button:active,
              .el-button:focus,
              .el-button:hover {
                color: #fff;
                border-color: #D42A20;
                background: #D42A20;
              }
            }

            &.all-withdrawal {
              .btn-text {
                color: #D42A20;
              }

            }
          }

          .btn-text {
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
  }

</style>
