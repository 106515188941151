<template>
  <div class="monthlyBalance-container">
    <div class="monthlyBalance-place">
      <div class="monthlyBalance-module">
        <div class="content">
          <div class="header">
            <div class="tabs">
              <el-tabs tab-position="left" :value="tabsConfig.current" @tab-click="tabClickFun">
                <template v-for="(item,index) in tabsConfig.options">
                  <el-tab-pane :label="item.label" :name="item.name" :key="index"></el-tab-pane>
                </template>
              </el-tabs>
            </div>
          </div>
          <div class="main">
            <keep-alive>
              <component :is="tabsConfig.options[tabsConfig.current].component"></component>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ATabPage from '@/views/insurance/child-pages/wallet/child-pages/monthly-balance/components/ATabPage.vue'
  import BTabPage from '@/views/insurance/child-pages/wallet/child-pages/monthly-balance/components/BTabPage.vue'
  import CTabPage from '@/views/insurance/child-pages/wallet/child-pages/monthly-balance/components/CTabPage.vue'
  export default {
    // 允许组件模板递归地调用自身
    name: 'monthly-balance',
    // 声明一组可用于组件实例中的组件
    components: {
      'aTabPage': ATabPage,
      'bTabPage': BTabPage,
      'cTabPage': CTabPage,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        tabsConfig: {
          current: '0',
          component: 'aTabPage',
          options: [{
            label: '额度明细',
            name: '0',
            component: 'aTabPage'
          }, {
            label: '已用金额明细',
            name: '1',
            component: 'bTabPage'
          }, {
            label: '待结算明细',
            name: '2',
            component: 'cTabPage'
          }],
        }
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 设置当前页面缓存
       */
      setAtPageSessionFun(val) {
        this.mySetSession(this.$options.name, val)
      },
      /**
       * 移除当前页面缓存
       */
      removeAtPageSessionFun() {
        this.myRemoveSession(this.$options.name)
      },
      /**
       * tab 点击
       */
      tabClickFun(e, url) {
        console.log('tab 点击 e == ', e)
        console.log('e.name == ', e.name)
        let {
          name
        } = e;
        // 事件决策
        this.eventDecisionFun(name)
      },

      /**
       * 事件决策
       */
      eventDecisionFun(name) {
        name = String(name);
        this.tabsConfig.current = name;
        let {
          type
        } = this;
        console.log('事件决策 type == ', type)

        switch (type) {
          case 'link':
            this.executeLinkFun(name);
            break;
          case 'tabs':
            this.executeTabsFun(name);
            break;
        }
      },
      /**
       * 执行跳转
       */
      executeLinkFun(name) {
        let {
          tabs_data
        } = this;
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .monthlyBalance-container {
    box-sizing: border-box;
    // min-height: 100vh;
    // background: #f5f5f5;
  }

  .monthlyBalance-place {
    box-sizing: border-box;
    // padding: 0 $theme-widht-whiteedge;
    // max-width: $theme-view-widht;
    // min-width: $theme-view-min-widht;
    width: 100%;
    margin: 0 auto;
  }

  .monthlyBalance-module {
    $rowGutter: 35px;

    .content {
      box-sizing: border-box;
      border-radius: 10px;
      overflow: hidden;
      display: flex;

      .header {
        box-sizing: border-box;
        background: #fff;
        border-right: 2px solid #E4E7ED;

        // padding: 0 $rowGutter;
        .tabs {
          &::v-deep {
            .el-tabs__header {
              margin: 0;
            }

            .el-tabs__nav-wrap::after {
              // display: none;
            }

            .el-tabs__item {
              $height: 5px;
              // padding-top:  $height;
              // padding-bottom:  $height;
              padding: 15px $rowGutter;
              height: auto;
              font-size: 18px;
              color: #444343;
              min-width: 184px;
              text-align: center;


              &.is-active {
                // color: #D42A20;
                color: #D42A20;
              }
            }

            .el-tabs__active-bar {
              // background: #D42A20;
              background: transparent;
            }

            .el-tabs__nav-wrap::after {
              background: transparent;
            }
          }
        }
      }

      .main {
        flex: 1;
        min-width: 0;
        box-sizing: border-box;
        // padding: 0 $rowGutter;
      }
    }

  }

</style>
