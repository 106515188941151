<template>
  <div class="claims-records-container">
    <div class="block-module">
      <div class="content-mian">
        <div class="claims-process-module">
          <div class="cpm-flow">
            <div class="flow-head">
              <div class="flow-title">
                <span class="title-text">理赔流程</span>
              </div>
            </div>
            <div class="flow-continer">
              <ul class="flow-form">
                <li class="ff-item">
                  <div class="ff-icon">
                    <div class="icon-box"
                      :style="'background-image: url(\'' + ossUrl + '/images/insurance-img/claims-process (1).png\')'">
                    </div>
                    <p class="icon-name diff-style">拨打&nbsp;0755-25156066&nbsp;报案</p>
                    <p class="icon-name diff-style" style="margin-top: 4px;">18805691865&nbsp;&nbsp;&nbsp;</p>
                  </div>
                  <div class="ff-arrow">
                    <el-image :src="ossUrl + '/images/insurance-img/claims-process-flow.png'" fit="scale-down">
                    </el-image>
                  </div>
                </li>
                <li class="ff-item">
                  <div class="ff-icon">
                    <div class="icon-box"
                      :style="'background-image: url(\'' + ossUrl + '/images/insurance-img/claims-process (2).png\')'">
                    </div>
                    <p class="icon-name">准备理赔资料</p>
                  </div>
                  <div class="ff-arrow">
                    <el-image :src="ossUrl + '/images/insurance-img/claims-process-flow.png'" fit="scale-down">
                    </el-image>
                  </div>
                </li>
                <li class="ff-item">
                  <div class="ff-icon">
                    <div class="icon-box"
                      :style="'background-image: url(\'' + ossUrl + '/images/insurance-img/claims-process (3).png\')'">
                    </div>
                    <p class="icon-name">寄送审核</p>
                  </div>
                  <div class="ff-arrow">
                    <el-image :src="ossUrl + '/images/insurance-img/claims-process-flow.png'" fit="scale-down">
                    </el-image>
                  </div>
                </li>
                <li class="ff-item">
                  <div class="ff-icon">
                    <div class="icon-box"
                      :style="'background-image: url(\'' + ossUrl + '/images/insurance-img/claims-process (4).png\')'">
                    </div>
                    <p class="icon-name">结案打款</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="flow-center">
              <p class="p-text">
                <span>简单----拨打电话，</span>
                <span class="strong-text">7x24小时专人服务</span>
              </p>
              <p class="p-text">
                <span>快捷----3000元以内案件，初审通过后，</span>
                <span class="strong-text">72小时快速赔付</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-mian">
      <div class="form-container">
        <div class="form-module">
          <div class="fm-tr">
            <div class="fm-itme fixation-width">
              <div class="fm-key">
                <div class="key-title required-style">报案时间：</div>
              </div>
              <div class="fm-val">
                <div class="picker-date">
                  <el-date-picker v-model="start_end_time" type="datetimerange" range-separator="至"
                    start-placeholder="请选择" end-placeholder="请选择" placeholder="请选择您的报案时间"
                    value-format="yyyy-MM-dd HH-mm-ss"
                    @change="function(e) {formData.add_start_time = e[0]; formData.add_end_time = e[1];}">
                  </el-date-picker>
                </div>
              </div>
            </div>
            <!-- <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">投保人姓名：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-autocomplete
                      v-model="formData.holder_name"
                      :class="{'isIphone8Up': facility.ios_grade > 2}"
                      :fetch-suggestions="function(e,cb) { cb(applicantQuerySearch(e,'name'))}"
                      placeholder="请输入投保人姓名"
                      @select="function(item){ formData.holder_name = item['name']}">
                      <template slot-scope="{ item }">
                        <div>{{ item.name }}</div>
                      </template>
                    </el-autocomplete>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">出险人证件号：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <!-- <el-input maxlength="18" v-model="formData.certificate_code" placeholder="请输入出险人证件号" @input="inputChangeFun($event,'certificate_code')"></el-input> -->
                    <el-autocomplete :class="{'isIphone8Up': facility.ios_grade > 2}"
                      v-model="formData.certificate_code"
                      :fetch-suggestions="function(e,cb) { cb(recognizeeQuerySearch(e,'code'))}" placeholder="请输入出险人证件号"
                      @select="function(item){ formData.certificate_code = item['code']}">
                      <template slot-scope="{ item }">
                        <div>{{ item.code }}</div>
                      </template>
                    </el-autocomplete>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">投保渠道：</div>
              </div>
              <div class="fm-val">
                <!-- <div class="cascader-box">
                  <el-cascader :options="[{value: '1', label: '1', children: [{value:'2',label: '2'}],}]" placeholder="请选择投保渠道" :show-all-levels="false" @change="categoryChangeFun"></el-cascader>
                </div> -->
                <div class="select-box">
                  <el-select v-model="formData.client_type" placeholder="请选择投保渠道">
                    <el-option label="pc" value="pc"></el-option>
                    <el-option label="小程序" value="weixin"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="fm-itme fixation-width">
              <div class="fm-key">
                <div class="key-title required-style">出险时间：</div>
              </div>
              <div class="fm-val">
                <div class="picker-date">
                  <el-date-picker v-model="order_start_end_time" type="datetimerange" range-separator="至"
                    start-placeholder="请选择" end-placeholder="请选择" placeholder="请选择您活动的开始时间"
                    value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
                    @change="function(e) {formData.accident_start_time = e[0]; formData.accident_end_time = e[1];}">
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">出险人姓名：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-autocomplete v-model="formData.name" :class="{'isIphone8Up': facility.ios_grade > 2}"
                      :fetch-suggestions="function(e,cb) { cb(recognizeeQuerySearch(e,'name'))}" placeholder="请输入出险人姓名"
                      @select="function(item){ formData.name = item['name']}">
                      <template slot-scope="{ item }">
                        <div>{{ item.name }}</div>
                      </template>
                    </el-autocomplete>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">被保人证件号：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-autocomplete
                      v-model="formData.insured_code"
                      :class="{'isIphone8Up': facility.ios_grade > 2}"
                      :fetch-suggestions="function(e,cb) { cb(recognizeeQuerySearch(e,'code'))}"
                      placeholder="请输入被保人证件号"
                      @select="function(item){ formData.insured_code = item['code']}">
                      <template slot-scope="{ item }">
                        <div>{{ item.code }}</div>
                      </template>
                    </el-autocomplete>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">状态：</div>
              </div>
              <div class="fm-val">
                <!-- <div class="cascader-box">
                  <el-cascader :options="[{value: '1', label: '1', children: [{value:'2',label: '2'}],}]" placeholder="请选择状态" :show-all-levels="false" @change="categoryChangeFun"></el-cascader>
                </div> -->
                <!-- pay_status: '1', //	否	string	状态【0待投保，1已完成，2已退保】 -->
                <div class="select-box">
                  <el-select v-model="formData.status" placeholder="请选择">
                    <el-option v-for="(item) in status_list" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="fm-itme fixation-width">
              <div class="fm-key">
                <div class="key-title required-style">理赔单号：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.order_no" placeholder="请输入理赔单号"
                      @input="inputChangeFun($event,'order_no')"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">保单号：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-autocomplete v-model="formData.trade_policy_no" :class="{'isIphone8Up': facility.ios_grade > 2}"
                      :fetch-suggestions="function(e,cb) { cb(policyNumberQuerySearch(e,'trade_policy_no'))}"
                      placeholder="请输入" @select="function(item){ formData.trade_policy_no = item['trade_policy_no']}">
                      <template slot-scope="{ item }">
                        <div>{{ item.trade_policy_no }}</div>
                      </template>
                    </el-autocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-module">
          <div class="fm-rest">
            <div class="fm-control">
              <div class="control-item">
                <el-button class="fake-btn" @click="clickSubmitBtnFun">
                  <span>立即查询</span>
                </el-button>
              </div>
              <div class="control-item">
                <el-button class="fake-btn" @click="clickReportCaseBtnFun">
                  <span>立即报案</span>
                </el-button>
              </div>
              <div class="control-item reset-btn">
                <el-button class="fake-btn" @click="clickResetBtnFun">
                  <span>重置</span>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-container">
        <p class="table-tips">注：出险后，第一时间报案并且送伤者前往二级以及二级以上公立医院就医，详情请查看理赔须知</p>
        <div class="settlement-table">
          <el-table border row-class-name="fake-table-tr" :data="settlementData.tableData.list" style="width: 100%">
            <el-table-column label-class-name="fake-table-th" width="150px" align="center" prop="user_id" label="理赔单号">
              <!-- <template slot-scope="scope">
                  <span v-if="(scope.$index + 1) < 10">00{{scope.$index + 1}} </span>
                  <span v-else-if="(scope.$index + 1) >= 10 || (scope.$index + 1) < 100">0{{scope.$index +1}} </span>
                  <span v-else>{{scope.$index + 1 }} </span>
                </template> -->
            </el-table-column>
            <el-table-column label-class-name="fake-table-th" width="230px" align="center" prop="name" label="出险人">
            </el-table-column>
            <el-table-column label-class-name="fake-table-th" align="center" prop="trade_policy_no" label="保单号">
            </el-table-column>
            <el-table-column label-class-name="fake-table-th" align="center" prop="add_time_date" label="报案时间">
            </el-table-column>
            <el-table-column label-class-name="fake-table-th" align="center" prop="accident_time_date" label="出险时间">
            </el-table-column>
            <el-table-column label-class-name="fake-table-th" align="center" label="理赔状态">
              <template slot-scope="scope">
                <span>{{ scope.row.status_text }}</span>
                <span class="single-failure" v-if="scope.row.status == 3 && scope.row.check_explain!='' ">
                  <el-tooltip effect="dark" content="" placement="top">
                    <div slot="content">
                      <p>{{ scope.row.check_explain }}</p>
                    </div>
                    <em class="icon">
                      <i class="iconfont icon-wenhao-xianxingyuankuang"></i>
                    </em>
                  </el-tooltip>
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label-class-name="fake-table-th"
              align="center"
              prop="text_6"
              label="跟进状态">
            </el-table-column>
            <el-table-column
              label-class-name="fake-table-th"
              align="center"
              prop="text_7"
              label="参考资料">
            </el-table-column> -->
            <el-table-column label-class-name="fake-table-th" align="center" label="操作">
              <template slot-scope="scope">
                <div class="control-list">
                  <span class="control-item" :data-data="scope.$index"
                    @click="delClaimDataFun(scope.row,scope.$index)">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-overview">
          <div class="table-paging">
            <el-pagination background layout="prev, pager, next" :current-page="formData.page"
              :page-count="settlementData.resData.page_total" :total="settlementData.resData.total"
              @current-change="pageChageFun">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗-立即报案 -->
    <settlement-form-dialog :ref="reportCaseData.dialogParam.ref" :visible="reportCaseData.dialogParam.visible"
      @on-change="reportCaseChangeFun" />

    <!-- 对话框 -->
    <my-dialog :visible="myDialog.visible" :options="myDialog.options" @on-change="myDialogChangeFun" />

  </div>
</template>

<script>
  import simblateTable from '@/views/insurance/components/simblate-table/SimulateTable.vue';
  import settlementFormDialog from '@/views/insurance/child-pages/claims-records/template/settlement-form-dialog.vue';
  export default {
    // 允许组件模板递归地调用自身
    name: 'ClaimsRecords',
    // 声明一组可用于组件实例中的组件
    components: {
      simblateTable,
      settlementFormDialog
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        ossUrl: this.GLOBAL.ossUrl,
        facility: {}, // 当前设备
        page_total: 1, // null, // 数据总页数
        data_total: 1, // null, // 数据总条数
        table_list: [], // 表格列表
        start_end_time: '', // 开始结束时间
        order_start_end_time: '', // 出单日期
        status_list: [], // 理赔状态选项
        formData: {
          page: 1, //	否	string	分页码 默认值：1
          page_size: 10, //	否	string	每页显示条数 默认值：10
          add_start_time: '', //	否	string	报案开始时间（格式：yyyy-mm-dd/yyyy-mm-dd hh:ii:ss）
          add_end_time: '', //	否	string	报案结束时间（格式：yyyy-mm-dd/yyyy-mm-dd hh:ii:ss）
          client_type: '', //	否	string	报案渠道【pc，weixin】
          accident_start_time: '', //	否	string	出险开始时间（格式：yyyy-mm-dd/yyyy-mm-dd hh:ii:ss）
          accident_end_time: '', //	否	string	出险结束时间（格式：yyyy-mm-dd/yyyy-mm-dd hh:ii:ss）
          name: '', //	否	string	出险人姓名
          certificate_type: '', //	是	string	出险人证件类型【1身份证，2护照】
          certificate_code: '', //	是	string	出险人证件号
          order_no: '', //	是	string	理赔单号
          status: '', //	是	string	状态【1已报案】
          trade_policy_no: '', // 保单号
        },
        inputHistoryData: { // 历史输入数据
          applicant: [], // 投保人
          recognizee: [], // 被保人
          allPolicy: [], // 所有保单
        },
        reportCaseData: { // 申请报案
          dialogParam: {
            ref: 'settlement-form-dialog',
            visible: false,
          },
        },
        settlementData: { // 表格数据
          resData: {
            page_total: 0,
            total: 0,
          },
          tableData: {
            radio: 0,
            checked: 0,
            checkList: [],
            list: []
          }
        },
        myDialog: { // 询问弹框
          data: {},
          visible: false,
          options: {},
        },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 投保人-历史输入-输入建议
       */
      applicantQuerySearch(queryString, key) {
        var restaurants = this.inputHistoryData.applicant;
        var results = queryString ? restaurants.filter(this.createFilter(queryString, key)) : restaurants;
        return results;
      },
      /**
       * 被保人-历史输入-输入建议
       */
      recognizeeQuerySearch(queryString, key) {
        var restaurants = this.inputHistoryData.recognizee;
        var results = queryString ? restaurants.filter(this.createFilter(queryString, key)) : restaurants;
        return results;
      },
      /**
       * 保单号-历史输入-输入建议
       */
      policyNumberQuerySearch(queryString, key) {
        var data = this.inputHistoryData.allPolicy;
        var results = queryString ? data.filter(this.createFilter(queryString, key)) : data;
        return results;
      },
      /**
       * 历史输入-输入建议-过滤
       */
      createFilter(queryString, key) {
        return (data) => {
          return (data[key].toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      /**
       * 历史输入-输入建议-过滤
       */
      createFilter(queryString, key) {
        return (restaurant) => {
          return (restaurant[key].toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      /**
       * 报案回调
       */
      reportCaseChangeFun(e) {
        console.log('报案回调 e == ', e)
        this.reportCaseData.dialogParam.visible = false; // 关闭弹窗
        let {
          status,
          data
        } = e;
        if (status == 1) {
          // 获取理赔数据
          this.getClaimDataFun();
        }
        console.log('this.reportCaseData == ', this.reportCaseData)
      },
      /**
       * 初始化页面数据
       */
      initPageDataFun() {
        this.start_end_time = ''; // 开始结束时间
        this.order_start_end_time = ''; // 出单日期
        this.formData = {
          page: 1, //	否	string	分页码 默认值：1
          page_size: 10, //	否	string	每页显示条数 默认值：10
          add_start_time: '', //	否	string	报案开始时间（格式：yyyy-mm-dd/yyyy-mm-dd hh:ii:ss）
          add_end_time: '', //	否	string	报案结束时间（格式：yyyy-mm-dd/yyyy-mm-dd hh:ii:ss）
          client_type: '', //	否	string	报案渠道【pc，weixin】
          accident_start_time: '', //	否	string	出险开始时间（格式：yyyy-mm-dd/yyyy-mm-dd hh:ii:ss）
          accident_end_time: '', //	否	string	出险结束时间（格式：yyyy-mm-dd/yyyy-mm-dd hh:ii:ss）
          name: '', //	否	string	出险人姓名
          certificate_type: '', //	是	string	出险人证件类型【1身份证，2护照】
          certificate_code: '', //	是	string	出险人证件号
          order_no: '', //	是	string	理赔单号
          status: '', //	是	string	状态【1已报案】
          trade_policy_no: '', // 保单号
        };
      },
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取设备信息
        this.getFacilityInfo();
        // 初始化页面数据
        this.initPageDataFun();
        // 获取理赔数据
        this.getClaimDataFun();
        // 初始化历史输入
        this.initInputHistory();
        // 获取有效保单
        this.getEffectivePolicyFun();
      },
      /**
       * 获取设备信息
       */
      getFacilityInfo() {
        let facility = this.myGetCurrentFacility();
        console.log('获取设备信息 facility == ', facility)
        this.facility = facility;
      },
      /**
       * 获取有效保单
       */
      getEffectivePolicyFun() {
        this.myRequest({
          url: '/api/insurance/effectiveinsuredorderall',
          data: {
            trede_policy_no: '',
          }
        }).then(res => {
          console.log('获取有效保单 res == ', res);
          let resData = res.data.data;
          this.inputHistoryData.allPolicy = resData;
        })
      },
      /**
       * 初始化历史输入
       */
      initInputHistory() {
        // 投保人
        let applicant = this.STORAGE.input_history['child_name']['applicant'];
        console.log('获取历史输入 name == ', name)
        // 获取历史输入
        let applicant_inputHistory = this.myGetStorageInputHistory(applicant);
        if (applicant_inputHistory) {
          let {
            target = []
          } = applicant_inputHistory;
          if (target.length > 0) {
            this.inputHistoryData.applicant = target;
          }
        }

        // 被保人
        let recognizee = this.STORAGE.input_history['child_name']['recognizee'];
        console.log('获取历史输入 name == ', name)
        // 获取历史输入
        let recognizee_inputHistory = this.myGetStorageInputHistory(recognizee);
        if (recognizee_inputHistory) {
          let {
            target = []
          } = recognizee_inputHistory;
          if (target.length > 0) {
            this.inputHistoryData.recognizee = target;
          }
        }
        console.log('获取历史输入 this.inputHistoryData == ', this.inputHistoryData)
      },
      /**
       * 分页切换
       */
      pageChageFun(e) {
        console.log('分页切换 e == ', e)
      },
      /**
       * 类别变换
       */
      categoryChangeFun(e) {
        console.log('类别变换 == ', e)
        console.log('类别变换 this.category_options == ', this.category_options)
      },
      /**
       * 点击报案按钮
       */
      clickReportCaseBtnFun() {
        // 打开报案弹窗
        this.reportCaseData.dialogParam.visible = true;
      },
      /**
       * 点击重置按钮
       */
      clickResetBtnFun() {
        console.log('重置')
        // 初始化页面数据
        this.initPageDataFun();
        // 获取理赔数据
        this.getClaimDataFun();
      },
      /**
       * 点击提交按钮
       */
      clickSubmitBtnFun() {
        console.log('点击提交按钮 this.formData == ', this.formData)
        // 获取理赔数据
        this.getClaimDataFun();
      },
      /**
       * 获取理赔数据
       */
      getClaimDataFun() {
        this.myRequest({
          url: '/api/insurance/compensationlist',
          data: this.formData
        }).then((res) => {
          console.log('获取理赔数据 res == ', res)
          let resData = res.data.data;
          if (resData.list.length == 0) {
            // this.myMessage({
            //   message: '查无数据！'
            // })
          }
          this.settlementData.resData = resData;
          this.settlementData.tableData.list = resData.list;
          this.status_list = resData.status_list;
        })
      },
      /**
       * 删除理赔数据
       */
      delClaimDataFun(item, index) {
        console.log('删除理赔数据 item == ', item)
        console.log('删除理赔数据 index == ', index)
        this.myDialog = {
          data: item,
          visible: true,
          options: {
            title: '删除',
            message: `确认要删除名为[${item.name}]的数据？`,
            is_tips: false,
            sign: 'claim',
          },
        }
      },
      /**
       * 删除理赔数据-请求
       */
      delClaimDataRequest() {
        let {
          data
        } = this.myDialog;
        console.log('删除理赔数据 data == ', data)
        this.myRequest({
          url: '/api/insurance/compensationdelete',
          data: {
            id: data.id,
          }
        }).then((res) => {
          console.log('删除理赔数据 res == ', res)
          let resData = res.data;
          this.myMessage({
            type: 'success',
            message: resData.msg,
          })
          // 获取理赔数据
          this.getClaimDataFun();
        })
      },
      /**
       * 初始化对话框
       */
      initMyDialogFun() {
        this.myDialog = {
          data: {},
          visible: false,
          options: {},
        }
      },
      /**
       * 对话框回调
       */
      myDialogChangeFun(e) {
        console.log('对话框回调 e == ', e);
        let {
          visible,
          status,
          message,
          data,
          name,
          sign
        } = e;
        if (status == 1) { // 确认
          // 匹配与作用
          this.matchingAndEffectFun(sign);

        } else { // 取消
          // 初始化对话框
          this.initMyDialogFun();
        }
      },
      /**
       * 匹配与作用
       */
      matchingAndEffectFun(sign) {
        switch (sign) {
          case 'claim':
            // 删除理赔数据-请求
            this.delClaimDataRequest()
            break;
        }

        // 初始化对话框
        this.initMyDialogFun();
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .claims-records-container {
    box-sizing: border-box;

    .content-mian {
      box-sizing: border-box;
      padding: 0 $theme-widht-whiteedge;
      // width: $theme-view-widht;
      max-width: $theme-view-widht;
      min-width: $theme-view-min-widht;
      margin: 0 auto;
    }
  }

  .claims-process-module {
    position: relative;
    padding: 42px 0 87px;

    .cpm-flow {
      text-align: center;

      .flow-head {
        box-sizing: border-box;
        padding: 0 140px;

        .flow-title {
          margin-bottom: 68px;
          text-align: center;
          font-weight: 400;
          font-size: 20px;
          color: #444343;

          .title-text {
            box-sizing: border-box;
            padding: 0 37px;
          }
        }
      }

      .flow-continer {
        display: inline-block;
        width: auto;
        margin: 0 auto;
      }

      .flow-form {
        $icon-size: 80px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 50px;
        border-radius: 30px 30px 0 0;
        overflow: hidden;

        .ff-item {
          display: flex;

          .ff-icon {
            .icon-box {
              margin: 0 auto;
              width: $icon-size;
              height: $icon-size;
              line-height: $icon-size;
              text-align: center;
              color: #fff;
              background-repeat: no-repeat;
              background-size: 100%;

              .iconfont {
                font-size: 32px;
              }
            }

            .icon-name {
              margin-top: 16px;
              font-size: 16px;
              color: #444343;

              .diff-style {
                width: 110px;
                white-space: nowrap;
                position: relative;
                left: -80%;
                transform: translateX(50%);
              }
            }
          }

          .ff-arrow {
            width: 70px;
            height: 40px;
            margin: 0 50px;
            margin-top: calc((#{$icon-size} / 2) - (40px / 2));
          }
        }
      }

      .flow-center {
        box-sizing: border-box;
        padding: 0 262px;
        margin-top: 80px;
        text-align: left;

        .p-text {
          margin-top: 25px;
          font-size: 20px;
          color: #444343;

          .strong-text {
            font-size: 24px;
            color: #E62129;
          }

          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
  }

  .table-container {
    margin-top: 86px;

    .table-tips {
      margin-bottom: 15px;
      font-size: 14px;
      color: #E62129;
    }

    .settlement-table {
      margin-bottom: 50px;

      &::v-deep {
        .el-checkbox__label {
          display: none;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: transparent;
          border-color: #E62129;
        }

        .el-checkbox__input.is-focus .el-checkbox__inner,
        .el-checkbox__inner::after {
          border-color: #E62129;
        }

        .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
          background-color: #E62129;
        }
      }

      &::v-deep {
        .el-table th.el-table__cell {
          background: #F0F0F0;
        }

        .fake-table-th {
          padding: 10px 0;
          background: #F0F0F0;
          font-weight: 400;
          font-size: 18px;
          color: #444343;
        }

        .fake-table-tr {
          font-size: 16px;
          color: #787878;
          background: #fff;
        }

        .el-table--border,
        .el-table--group {
          border: 1px solid #e6e6e6;
        }

        .el-table--border .el-table__cell,
        .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
        .el-table--border th.el-table__cell,
        .el-table__fixed-right-patch {
          border: none;
        }

        .el-table td.el-table__cell,
        .el-table th.el-table__cell.is-leaf {
          border-top: 1px solid #f0f0f0;
        }

        .el-table--border::after,
        .el-table--group::after,
        .el-table::before {
          display: none;
        }

        .el-table,
        .el-table__expanded-cell,
        .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
          background: transparent;
        }

        .el-table .el-table__cell {
          height: 66px;
        }
      }

      .control-list {
        .control-item {
          margin: 0 8px;
          display: inline-block;
          font-size: 16px;
          color: #E62129;
        }
      }
    }

    .table-overview {
      .table-paging {
        margin-bottom: 50px;
        text-align: center;
      }
    }
  }

  .details-form {
    display: flex;

    .df-item {
      margin-right: 35px;
      font-size: 16px;
      color: #787878;
    }
  }


  .form-container {
    .form-module {

      // margin-top: 50px;
      .fm-itme {
        // width: 33.33%;
        width: 30%;

        &.fixation-width {
          width: 40%;

          .fm-val {
            .picker-date .el-range-editor.el-input__inner {
              width: 100%;
            }

            .field-item .input-box {
              max-width: initial;
            }
          }
        }
      }

      .fm-rest {
        .fm-control {
          margin-top: 50px;
        }
      }
    }
  }

  .single-failure {
    position: relative;

    .icon {
      z-index: 1;
      position: relative;
      top: -5px;
      right: -5px;

      .iconfont {
        font-size: 16px;
        color: #E62129;
      }
    }
  }

</style>
