<template>
  <div class="container">
    <div class="transaction-detail">
      <div class="header">
        <div class="form">
          <div class="item">
            <div class="key">选择日期：</div>
            <div class="val">
              <!-- <div class="field">
                <el-date-picker v-model="withdrawRecordObject.formData.start_time" :editable="false" type="datetime"
                  placeholder="选择日期" format="yyyy 年 MM 月 dd 日 HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                  @change="withdrawRecordDataChangeFun">
                </el-date-picker>
              </div> -->
              <div class="field">
                <el-date-picker v-model="start_end_date" type="datetimerange" :editable="false" start-placeholder="开始日期"
                  end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd" @change="withdrawRecordDataChangeFun">
                </el-date-picker>
                <!-- <el-date-picker v-model="withdrawRecordObject.formData.start_time" :editable="false" type="datetime"
                  placeholder="选择日期" format="yyyy 年 MM 月 dd 日 HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
                  @change="withdrawRecordDataChangeFun">
                </el-date-picker> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="main-header">
          <div class="table-place">
            <!-- <el-table border row-class-name="fake-table-tr" :data="withdrawRecordObject.tableData.list"
              style="width: 100%;" maxHeight="600" v-el-table-infinite-scroll="withdrawRecordScrollPageFun"> -->
            <el-table border row-class-name="fake-table-tr" :data="withdrawRecordObject.tableData.list"
              style="width: 100%;">
              <el-table-column label-class-name="fake-table-th" align="center" prop="add_time" label="交易时间">
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" align="center" prop="describe" label="交易来源">
              </el-table-column>
              <el-table-column label-class-name="fake-table-th" align="center" prop="operation_money" label="交易金额">
                <template slot-scope="scope">
                  <div class="transaction-amount">
                    <div class="ta-item ta-puls" v-if="scope.row.operation_type == 1">
                      <div class="ta-unit">
                        <i class="iconfont icon-jiahao"></i>
                      </div>
                      <span class="ta-price">{{scope.row.operation_money}}</span>
                    </div>
                    <div class="ta-item ta-minus" v-else>
                      <div class="ta-unit">
                        <i class="iconfont icon-jian"></i>
                      </div>
                      <span class="ta-price">{{scope.row.operation_money}}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="table-paging">
          <div class="center-place">
            <span class="sum-number">共{{withdrawRecordObject.resData.total}}条记录</span>
            <el-pagination background layout="prev, pager, next" :current-page="withdrawRecordObject.formData.page"
              :page-size="withdrawRecordObject.formData.page_size" :page-count="withdrawRecordObject.resData.page_total"
              :total="withdrawRecordObject.resData.total" @current-change="withdrawRecordPageChageFun">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    // 允许组件模板递归地调用自身
    name: 'transaction-detail',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        start_end_date: '', // 开始结束站位字段
        withdrawRecordObject: { // 明细
          loading: false,
          noMore: false,
          formData: { // 参数
            page: 1, // 否 string	分页码 默认值：1
            page_size: 10, // 否 string	每页显示条数 默认值：10
            start_time: '', // 否	string	开始时间 参数格式:2019-10-20 15:30:25
            end_time: '', //	否	string	结束时间 参数格式:2019-10-20 15:30:25
          },
          resData: { // 响应数据
            page_total: 1, // null, // 数据总页数
            data_total: 1, // null, // 数据总条数
          },
          tableData: {
            radio: '',
            checked: 0,
            checkList: [],
            list: []
          }
        },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 初始化明细参数
        this.initWithdrawRecordFromDataFun()
        // 获取明细
        this.getWithdrawRecordFun();
      },
      /**
       * 初始化明细参数
       */
      initWithdrawRecordFromDataFun() {
        this.start_end_date = ''; // 开始结束站位字段

        let withdrawRecordObject = { // 明细
          loading: false,
          noMore: false,
          formData: { // 参数
            page: 1, // 否 string	分页码 默认值：1
            page_size: 10, // 否 string	每页显示条数 默认值：10
            start_time: '', // 否	string	开始时间 参数格式:2019-10-20 15:30:25
          },
          resData: { // 响应数据
            page_total: 1, // null, // 数据总页数
            data_total: 1, // null, // 数据总条数
          },
          tableData: {
            radio: '',
            checked: 0,
            checkList: [],
            list: []
          }
        };
        this.withdrawRecordObject = withdrawRecordObject;
      },
      /**
       * 明细-日期选择
       */
      withdrawRecordDataChangeFun(e) {
        console.log('明细-日期选择 e == ', e);
        this.withdrawRecordObject.formData.start_time = e[0] + ' 00:00:00';
        this.withdrawRecordObject.formData.end_time = e[1] + ' 23:59:59';
        // 清空-明细
        this.emptyWithdrawRecordPages();
        // 获取明细
        this.getWithdrawRecordFun();
      },
      /**
       * 获取明细
       */
      getWithdrawRecordFun() {
        let {
          formData
        } = this.withdrawRecordObject;
        console.log('获取明细 参数 == ', formData)
        this.myRequest({
            url: '/api/plugins/index&pluginsname=wallet&pluginscontrol=walletlog&pluginsaction=index',
            data: formData,
          })
          .then((res) => {
            console.log('获取明细 res == ', res)
            let resData = res.data.data;
            this.withdrawRecordObject.resData = Object.assign(this.withdrawRecordObject.resData, resData);
            this.withdrawRecordObject.tableData.list = this.withdrawRecordObject.tableData.list.concat(resData.list);
            this.withdrawRecordObject.loading = true;
            console.log('获取明细 this.withdrawRecordObject == ', this.withdrawRecordObject)
          }).catch((err) => {
            console.log('获取明细 err == ', err)
          })
      },
      /**
       * 清空-明细
       */
      emptyWithdrawRecordPages(e) {
        e = e || 1;
        this.withdrawRecordObject.formData.page = e;
        this.withdrawRecordObject.tableData.list = [];
      },
      /**
       * 明细-分页切换
       */
      withdrawRecordPageChageFun(e) {
        console.log('明细-分页切换 e == ', e)
        // this.withdrawRecordObject.formData.page = e;
        // 清空-明细
        this.emptyWithdrawRecordPages(e);
        // 获取明细
        this.getWithdrawRecordFun();
      },
      /**
       * 明细-滚动分页
       */
      withdrawRecordScrollPageFun(e) {
        console.log('明细-滚动分页 e == ', e)
        let {
          tableData,
          resData
        } = this.withdrawRecordObject;
        if (tableData.list.length >= resData.total) return;

        this.withdrawRecordObject.formData.page += 1;
        // 清空-明细
        // this.emptyWithdrawRecordPages();
        // 获取明细
        this.getWithdrawRecordFun();
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
  }

  .transaction-detail {


    .header {
      box-sizing: border-box;
      padding: 50px 35px 0;
      background: #fff;

      .form {

        .item {
          display: flex;
          align-items: center;

          .key {
            font-size: 18px;
            color: #444343;
          }

          .val {
            .field {}
          }
        }
      }
    }

    .main {
      box-sizing: border-box;

      .main-header {
        box-sizing: border-box;
        padding: 35px;
        border-radius: 0 0 10px 10px;
        overflow: hidden;
        background: #fff;
      }

      .table-place {
        width: auto;
        min-width: 930px;
        min-height: 530px;

        &::v-deep {

          // .el-table th.el-table__cell {
          //   background: #F0F0F0;
          // }
          .fake-table-th {
            background: #E6E6E6;
            font-weight: 500;
            font-size: 18px;
            color: #444343;
          }

          .fake-table-tr {
            font-size: 16px;
            color: #787878;
            background: #fff;
          }

          // .el-table--border, .el-table--group {
          //   border: 1px solid #e6e6e6;
          // }
          // .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
          // .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
          //   border: none;
          // }
          // .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
          //   border-top: 1px solid #f0f0f0;
          // }
          // .el-table--border::after, .el-table--group::after, .el-table::before {
          //   display: none;
          // }
          // .el-table, .el-table__expanded-cell,
          // .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
          //   background: transparent;
          // }
          // .el-table .el-table__cell {
          //   height: 66px;
          // }
        }

        .transaction-amount {
          display: flex;
          font-size: 18px;
          color: #d42a20;

          .ta-item {
            flex: auto;
            min-width: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .ta-unit {
            width: 30px;
            margin-right: 2px;

            .iconfont {
              font-weight: 700;

              &.icon-jiahao {
                font-size: 16px;
              }

              &.icon-jian {
                font-size: 12px;
              }
            }
          }

          .ta-price {
            width: auto;
            min-width: 60px;
            text-align: left;
          }
        }
      }

      .table-paging {
        box-sizing: border-box;
        padding: 0 35px 35px;

        .center-place {
          display: flex;
          align-items: center;

          .sum-number {
            font-size: 16px;
            color: #787878;
          }
        }
      }
    }
  }

</style>
