<template>
  <div class="container">
    <div class="account-recharge">
      <!-- <div class="ar-title">
        <span class="title-text">支付方式：</span>
        <span class="title-text">微信支付</span>
      </div> -->
      <div class="ar-price">
        <div class="price-place">
          <span class="price-key">月结额度：</span>
          <span class="price-value">
            <i class="iconfont icon-jiage"></i>
            <span class="val-text" v-if="walletBalanceConfig">{{walletBalanceConfig.user_wallet.buffering_money}}</span>
          </span>
        </div>
        <div class="price-place not-strong">
          <span class="price-key">已用金额：</span>
          <span class="price-value">
            <i class="iconfont icon-jiage"></i>
            <span class="val-text"
              v-if="walletBalanceConfig">{{walletBalanceConfig.user_wallet.buffering_use_money}}</span>
          </span>
        </div>
        <div class="price-place not-strong">
          <span class="price-key">待结算金额：</span>
          <span class="price-value">
            <i class="iconfont icon-jiage"></i>
            <span class="val-text"
              v-if="walletBalanceConfig">{{walletBalanceConfig.user_wallet.buffering_wait_money}}</span>
          </span>
        </div>
      </div>
      <!-- <div class="ar-form">
        <div class="af-item">
          <div class="af-field">
            <el-input placeholder="请输入充值金额" v-model="input_price">
              <i slot="prefix" class="iconfont icon-jiage"></i>
            </el-input>
          </div>
          <div class="af-control">
            <div class="control-item">
              <el-button class="fake-button" @click="confirmClick">
                <span class="btn-text">立即充值</span>
              </el-button>
            </div>
          </div>
        </div>
      </div> -->
    </div>


    <!-- 支付弹窗 -->
    <!-- <my-pay-dialog :model="1" :visible="pay_config.visible" :payee="pay_config.payee" :price="pay_config.price"
      :qr-code="pay_config.qr_code" :pay-mode="pay_config.select.pay_mode" @on-change="payResultChangeFun" /> -->
    <my-pay-dialog :model="1" :options="pay_config.options" @on-change="payResultChangeFun" />

    <!-- 选址支付方式 -->
    <select-pay-method :type="1" :visible="pay_config.select.visible" @on-change="choosePayResultChangeFun" />

  </div>
</template>

<script>
  import {
    deepClone, // 深拷贝
  } from '@/utils/utils_fun.js';
  export default {
    // 允许组件模板递归地调用自身
    name: 'account-recharge',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {

    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        input_price: '', // 输入金额
        walletBalanceConfig: null, // 钱包余额配置
        pay_config: { // 支付配置
          select: {
            metadata: {},
            visible: false,
            value: '',
            pay_mode: '',
            is_parent_pay: '',
          },
          // visible: false,
          // pay_timer: null,
          // payee: '',
          // price: '',
          // qr_code: '',
          options: { // 支付参数
            visible: false,
            title: {
              value: '充值到钱包',
              style: 'text-align: center; margin-bottom: 10px;',
            },
            descriptions: {
              name: '微信',
              value: ' 请使用微信扫一扫付款',
              style: '',
            },
            payee: {
              value: ' ',
              style: '',
            },
            price: {
              price: {
                value: '',
                style: 'color: #444343',
              },
              unit: {
                value: '￥',
                style: 'color: #444343',
              },
            },
            qrCode: {
              value: '',
              style: 'margin-top: 0;',
              status: 0,
            }
          }
        },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 初始化页面数据
        this.initPageDataFun();
        // 获取明细
        this.getWalletBalanceFun();
      },
      /**
       * 初始化页面数据
       */
      initPageDataFun() {
        this.input_price = ''; // 输入金额
        this.walletBalanceConfig = null; // 钱包余额配置
      },
      /**
       * 获取-钱包余额
       */
      getWalletBalanceFun() {
        this.walletBalanceConfig = null;
        this.myRequest({
            url: '/api/plugins/index&pluginsname=wallet&pluginscontrol=user&pluginsaction=index',
            data: {},
          })
          .then((res) => {
            console.log('钱包余额 res == ', res)
            let resData = res.data.data;
            this.walletBalanceConfig = resData;
            console.log('钱包余额 this.walletBalanceConfig == ', this.walletBalanceConfig)
          }).catch((err) => {
            console.log('钱包余额 err == ', err)
          })
      },
      //表单验证
      formVerify() {
        let {
          input_price
        } = this;

        if (!this.REGEXP.is_floating.test(input_price)) {
          this.myMessage({
            message: '请输入有效额度，可含小数点后两位!',
          });
          return false;
        }

        if (input_price == 0) {
          this.myMessage({
            message: '充值金额不能为0!',
          });
          return false;
        }

        return true;
      },
      /**
       * 确认-点击事件
       */
      confirmClick() {
        // 表单校验
        let flag = this.formVerify();
        if (!flag) return;
        console.log('请求参数1 == ', this.input_price)

        // 选择支付-开关
        this.choosePayTaggleFun(1);

      },
      /**
       * 生成订单
       */
      createOrderFun() {
        return new Promise((resolve, reject) => {
          this.myRequest({
              url: '/api/plugins/index&pluginsname=wallet&pluginscontrol=recharge&pluginsaction=create',
              data: {
                money: this.input_price,
              },
            })
            .then((res) => {
              console.log('生成订单 res == ', res)
              resolve(res);
            }).catch((err) => {
              console.log('生成订单 err == ', err)
            })
        })
      },
      /**
       * 支付-订单
       */
      payOrderRequestFun(order_id) {
        const _this = this;
        let pay_config = deepClone(this.pay_config);
        console.log('支付-订单 pay_config == ', pay_config)
        console.log('支付-订单 order_id == ', order_id)

        this.myRequest({
            url: '/api/plugins/index&pluginsname=wallet&pluginscontrol=recharge&pluginsaction=pay',
            data: {
              payment_id: pay_config.select.metadata.id, //	是	string	支付方式id
              recharge_id: order_id, //	是	string	充值订单id
            },
          })
          .then((res) => {
            console.log('支付-订单 res == ', res)
            let resData = res.data.data;

            if (res.data.code != 0) {
              this.myMessage({
                message: res.data.msg,
              });
              return;
            }

            // 如果是钱包支付
            if (pay_config.select.metadata.payment == 'WalletPay') {
              // 支付结果回调
              _this.payResultChangeFun({
                visible: false,
                status: 1, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
                message: '支付成功',
                data: '',
              });
            } else {

              // 循环请求
              this.myLoopRequest({
                id: resData.order_id,
                type: 'recharge_order'
              }, function (res) {
                console.log('myLoopRequest == ', res)
                let {
                  timer,
                  data
                } = res;

                // 获取定时器
                if (!_this.pay_config.pay_timer) {
                  _this.pay_config.pay_timer = timer;
                  // 打开支付弹窗
                  _this.openPayWindowFun({
                    payee: resData.order_payee,
                    price: _this.input_price,
                    qr_code: resData.data
                  })
                }
                console.log('_this.pay_config.pay_timer == ', _this.pay_config.pay_timer)

                console.log('data.pay_status == ', data.pay_status)
                // 是否已支付
                if (data.pay_status == 1) {
                  console.log('是否已支付 == ', data.pay_status)
                  // clearInterval(_this.pay_config.pay_timer);
                  // 支付结果回调
                  _this.payResultChangeFun({
                    visible: false,
                    status: 1, // 1/支付成功 2/取消支付 3/取消支付，关闭弹窗
                    message: '支付成功',
                    data: '',
                  });
                } else if (res.status == 3) { // 超时
                  // 支付结果回调
                  _this.payResultChangeFun(res);
                }
              })

            }


          }).catch((err) => {
            console.log('支付-订单 err == ', err)
          })

      },
      /**
       * 打开支付弹窗
       */
      openPayWindowFun(opt) {
        // this.pay_config.payee = opt.payee || '';
        // this.pay_config.price = opt.price || '';
        // this.pay_config.qr_code = opt.qr_code;
        // this.pay_config.visible = true;
        let {
          payee,
          price,
          qr_code
        } = opt;

        let price_float = Math.floor(price * 100) / 100;
        price_float = price_float.toFixed(2);

        this.pay_config.options.payee.value = payee;
        this.pay_config.options.price.price.value = price_float;
        this.pay_config.options.qrCode.value = qr_code;
        this.pay_config.options.visible = true;
      },
      /**
       * 支付结果回调
       */
      payResultChangeFun(e) {
        console.log('支付结果回调 e == ', e)
        let {
          visible,
          status,
          message,
          data
        } = e;
        // this.pay_config.visible = visible;
        this.pay_config.options.visible = visible;
        // 自定义清除定时器
        this._clearIntervalFun();
        // 是否支付成功
        if (status == 1) {

          // this.myMessage({
          //   message: message,
          // });
          // 发起录入后
          this.sendEnteringAfterFun();


        } else {
          this.myMessage({
            message: message,
          });
        }
      },
      /**
       * 发起录入后
       */
      sendEnteringAfterFun(data) {
        // 获取页面数据
        this.getPageDataFun();
        // 跳转参数
        let skip_qurey = {
          status: '1',
          status_msg: '账户充值成功！',
          descriptions: '',
          text_list: [''], // 文本
          source: 'insurance', // 来源
        };

        // 跳转路由
        this.myRouterPush({
          path: '/succeed',
          query: {
            data: encodeURIComponent(JSON.stringify(skip_qurey)),
          }
        });
      },
      /**
       * 自定义清除定时器
       */
      _clearIntervalFun() {
        if (this.pay_config.pay_timer) {
          clearInterval(this.pay_config.pay_timer);
          this.pay_config.pay_timer = null;
        }
      },
      /**
       * 选择支付-开关
       */
      choosePayTaggleFun(flag) {
        let result;
        switch (+flag) {
          case 1:
            result = true;
            break;
          case 2:
            result = false;
            break;
          default:
            result = !this.pay_config.select.visible;
            break;
        }
        this.pay_config.select.visible = result;
      },
      /**
       * 选择支付-结果回调
       */
      choosePayResultChangeFun(e) {
        console.log('选择支付结果回调 e == ', e)
        const _this = this;
        let {
          visible,
          status,
          message,
          data
        } = e;
        this.pay_config.select.visible = visible;
        // 自定义清除定时器
        this._clearIntervalFun();
        // 是否选择支付成功
        if (status == 1) {
          let {
            id,
            name,
            payment,
            is_parent_pay,
          } = data
          this.pay_config.select.metadata = data;
          this.pay_config.select.value = id;
          this.pay_config.select.pay_mode = name;
          this.pay_config.select.is_parent_pay = is_parent_pay;

          // 生成订单
          this.createOrderFun().then((res) => {
            this.payOrderRequestFun(res.data.data.recharge_id);
          })

        } else {
          this.myMessage({
            message: message,
          });
        }
      },
      /**
       * 成功跳转
       */
      succeedSkipToFun() {
        // 回退上一页
        wx.navigateBack({
          delta: 1
        })
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
  }

  .account-recharge {
    box-sizing: border-box;
    padding: 30px 35px;
    background: #fff;

    .ar-title {
      font-size: 18px;
      color: #787878;

      .title-text {
        &.diff-color {
          color: #444343;
        }
      }
    }

    .ar-price {
      margin: 0 0 24px;

      .price-place {
        // display: inline-block;
        box-sizing: border-box;
        // padding: 6px 24px;
        border-radius: 10px;
        // background: #FFF4DB;
        margin-bottom: 5px;

        &:first-of-type {
          margin-top: 0;
        }

        &.not-strong {
          margin-bottom: 0;

          .price-key {
            font-size: 18px;
          }

          .price-value {
            font-weight: 400;
            font-size: 18px;

            .val-text {
              position: relative;
              top: 0px;
            }

            .iconfont {
              &.icon-jiage {
                font-size: 16px;
              }
            }
          }
        }

        .price-key {
          font-size: 20px;
          color: #444343;
        }

        .price-value {
          font-weight: 700;
          font-size: 28px;
          color: #444343;

          // color: #D42A20;


          .val-text {
            position: relative;
            top: 2px;
          }

          .iconfont {
            line-height: 40px;

            &.icon-jiage {
              font-size: 20px;
            }
          }
        }
      }
    }

    .ar-form {

      .af-item {
        display: flex;

        .af-field {
          .iconfont {
            line-height: 40px;
            position: relative;
            left: 4px;
            // color: #D42A20;
            color: #444343;

            &.icon-jiage {}
          }

          &::v-deep {

            .el-input.is-active,
            .el-input__inner:focus {
              border-color: #DCDFE6;
            }
          }
        }

        .af-control {
          display: flex;
          align-items: center;

          .control-item {
            margin-left: 14px;

            .fake-button {
              color: #fff;
              background: #D42A20;
              cursor: pointer;
            }

            &::v-deep {
              .el-button {
                border-color: #D42A20;
                background: #D42A20;
              }

              .el-button:active,
              .el-button:focus,
              .el-button:hover {
                color: #fff;
                border-color: #D42A20;
                background: #D42A20;
              }
            }

            &.all-withdrawal {
              .btn-text {
                color: #D42A20;
              }

            }
          }

          .btn-text {
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
  }

</style>
