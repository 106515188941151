<template>
  <div class="dialog-container">
    <el-dialog width="736px" top="10vh" center :visible.sync="mapVisible" :close-on-click-modal="false"
      :before-close="dialogBeforeCloseFun">
      <div class="dialog-body">
        <div class="dialog-title">
          <h3 class="title-text">添加人员</h3>
        </div>
        <div class="dialog-main">
          <div class="form-module">
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style diff">手机号码：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.mobile" maxlength="11" placeholder="请输入"
                      @input="inputChangeFun($event,'mobile')"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">备注：</div>
              </div>
              <div class="fm-val">
                <div class="field-item">
                  <div class="input-box">
                    <el-input v-model="formData.notes" placeholder="请输入" @input="inputChangeFun($event,'notes')">
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="fm-itme">
              <div class="fm-key">
                <div class="key-title required-style">是否享用主帐号钱包余额：</div>
              </div>
              <div class="fm-val">
                <div class="select-box">
                  <el-select v-model="formData.share_wallet" placeholder="请选择">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-module">
            <div class="fm-rest">
              <div class="fm-control">
                <div class="control-item">
                  <el-button class="fake-btn" @click="clickSubmitBtnFun">
                    <span>提交</span>
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // 添加人员
  export default {
    // 允许组件模板递归地调用自身
    name: 'add-account-dialog',
    // 声明一组可用于组件实例中的组件
    components: {

    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Object,
        default: () => {
          let formData = {
            mobile: '', //	是	string	手机号码
            notes: '', //	是	string	备注
            share_wallet: '0', //	是	string	是否享用主帐号钱包余额【1是，0否】
          }
          return formData;
        }
      },
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        mapVisible: this.visible,
        formData: this.options,
        // formData: {
        //   mobile: '', //	是	string	手机号码
        //   notes: '', //	是	string	备注
        //   share_wallet: 0, //	是	string	是否享用主帐号钱包余额【1是，0否】
        // },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {
      visible: function (e) {
        this.mapVisible = e;
        // 初始化页面数据
        // this.initPageDataFun2();
      },
      options: function (e) {
        this.formData = e;
        console.log('options e == ', this.options)
        console.log('options == ', this.options)
        console.log('options formData == ', this.formData)
      },
    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 初始化页面数据
       */
      initPageDataFun() {
        this.formData = {
          mobile: '', //	是	string	手机号码
          notes: '', //	是	string	备注
          share_wallet: '0', //	是	string	是否享用主帐号钱包余额【1是，0否】
        };
      },
      /**
       * 初始化页面数据2
       */
      initPageDataFun2() {
        this.formData = {
          mobile: '13554468468', //	是	string	手机号码
          notes: '哈姆雷特', //	是	string	备注
          share_wallet: '0', //	是	string	是否享用主帐号钱包余额【1是，0否】
        };
      },
      /**
       * 输入框监听变化
       */
      inputChangeFun(event, name) {
        console.log(event)
        console.log(name)
        console.log(this.formData)
      },
      /**
       * 关闭弹窗前
       */
      dialogBeforeCloseFun(done) {
        done();
        console.log('关闭弹窗前 == ', this.mapVisible)
        // 告知弹窗变化
        this.informChangeFun();
      },
      /**
       * 告知弹窗变化
       */
      informChangeFun(opt) {
        let default_data = {
          visible: false,
          status: 3, // 1/成功 2/失败 3/取消
          message: '取消',
          data: '',
        };
        let result = Object.assign(default_data, opt);
        this.$emit('on-change', result)
      },
      /**
       * 类别变换
       */
      categoryChangeFun(e) {
        console.log('类别变换 == ', e)
        console.log('类别变换 this.category_options == ', this.category_options)
      },
      /**
       * 点击提交按钮
       */
      clickSubmitBtnFun() {

        // 校验数据
        let flag = this.verifyDataFun();
        if (!flag) return;

        // 发起录入请求
        this.sendEnteringRequestFun();
      },
      /**
       * 发起录入请求
       */
      sendEnteringRequestFun() {
        console.log('发起录入 this.formData == ', this.formData);
        let formData = this.formData;
        this.myRequest({
          method: 'post',
          url: '/api/sonaccount/save',
          data: formData,
        }).then((res) => {
          console.log('录入请求成功 res == ', res);
          let resData = res.data.data;
          // 发起录入后
          this.sendEnteringAfterFun();
        }).catch((err) => {
          console.log('录入请求失败 err == ', err)
        })
      },
      /**
       * 发起录入后
       */
      sendEnteringAfterFun(data) {
        // 初始化页面 data 数据
        let message = '新建成功！';
        // 如果有id，则为修改
        if (this.formData.id) {
          message = '修改成功！';
        }
        this.initPageDataFun();
        this.myMessage({
          type: 'success',
          message: message
        })
        // 告知弹窗变化
        this.informChangeFun({
          visible: false,
          status: 1, // 1/成功 2/失败 3/取消
          message: '成功',
          data: data,
        });
      },
      /**
       * 校验数据
       */
      verifyDataFun() {
        let form_data = this.formData;

        // mobile: '', //	是	string	手机号码
        // notes: '', //	是	string	备注
        // share_wallet: '0', //	是	string	是否享用主帐号钱包余额【1是，0否】

        // 咨询电话
        if (!form_data.mobile || !this.REGEXP.is_phone.test(form_data.mobile)) {
          this.myMessage({
            message: '请添加有效的电话!'
          });
          return false;
        }

        return true;
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {

    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  @import '@/views/insurance/css/common.scss';

  .dialog-container {
    box-sizing: border-box;
  }

  .dialog-body {
    .dialog-title {
      display: flex;
      align-items: center;

      .title-text {
        font-weight: 400;
        font-size: 18px;
        color: #444343;
      }

      .title-text2 {
        margin-left: 24px;
        cursor: pointer;
        text-decoration: underline;
        font-size: 12px;
        color: #E62129;
      }
    }

    .form-module {
      .fm-itme {
        &:first-of-type {
          margin-top: 45px;
        }

        .fm-key {
          min-width: 5rem;
        }

        .fm-val {

          .select-box,
          .cascader-box {
            max-width: 353px;
          }
        }
      }

      .fm-rest {
        .fm-control {
          margin-top: 68px;
        }
      }
    }
  }

</style>
